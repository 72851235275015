@import 'src/scss/tokens';

.container {
    position: relative;
}

.scroll-wrapper {
    overflow: auto;
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // IE 10+

    &::-webkit-scrollbar { // Chrome
        display: none;
    }
}

.list {
    display: inline-block;
    white-space: nowrap;

    li {
        display: inline-block;

        .tab {
            position: relative;
            display: flex;
            align-items: center;
            height: input-height(base);
            padding-left: spacing(base);
            padding-right: spacing(base);
            font-weight: font-weight(bold);
            font-size: font-size(large);
            color: color(gray, base);
            cursor: pointer;
            transition-property: background, color;
            transition-duration: transition-duration(base);
            transition-timing-function: $transition-timing-function;

            &:after {
                position: absolute;
                right: 0;
                bottom: 1px;
                left: 0;
                content: '';
                height: border-width(thick);
                background-color: color(gray, lighter);
                transition: background-color transition-duration(base) $transition-timing-function;
            }

            &:hover {
                color: color(gray, darkest);

                &:after {
                    background-color: color(gray, darkest);
                }
            }
        }

        &.active {
            .tab {
                color: color(gray, darkest);
                pointer-events: none;

                &:after {
                    bottom: 0;
                    height: border-width(thicker);
                    border-radius: border-radius(base);
                    background-color: color(purple, base);
                }
            }
        }
    }

    &.tab-size--small {
        li {
            .tab {
                padding: 0 spacing(small) spacing(small);
                font-size: font-size(base);

                &:after {
                    height: border-width(thick);
                }
            }

            &.active {
                .tab {
                    &:after {
                        bottom: 1px;
                    }
                }
            }
        }
    }
}

.tab-style--flush {
    margin: 0 (0 - spacing(smaller));

    li {
        padding: 0 spacing(smaller);

        .tab {
            padding: 0 spacing(small) spacing(smaller);

            &:after {
                bottom: 0;
                height: border-width(thicker);
                background-color: transparent;
            }

            &:hover {
                &:after {
                    background-color: color(gray, darkest);
                }
            }
        }

        &,
        &.active {
            .tab {
                &:after {
                    border-radius: border-radius(base) border-radius(base) 0 0;
                }
            }
        }
    }
}

.tab-style--block {
    margin: 0 (0 - spacing(smallest)) (0 - spacing(smaller));

    li {
        display: inline-block;
        padding: 0 spacing(smallest) spacing(smaller);

        &.active {
            .tab {
                font-weight: font-weight(bolder);
                background-color: color(purple, darkest);
                color: color(gray, white);
            }
        }

        .tab {
            border-radius: border-radius(round);
            color: color(gray, dark);
            padding: spacing(small) spacing(base);

            &:after {
                display: none;
            }

            &:hover {
                background-color: color(purple, darker);
                color: color(gray, white);
            }
        }
    }

    &.tab-size--small {
        li {
            .tab {
                padding: spacing(small) spacing(small);
            }
        }
    }

    &.light {
        .active {
            .tab {
                background-color: color(gray, lightest);
                color: color(gray, darkest);
            }
        }
    }
}

.tab-style--switch {
    background-color: color(gray, white);
    border: border-width(base) solid color(gray, lightest);
    border-radius: 999px;
    display: inline-block;
    margin: 0;
    padding: spacing(smaller) spacing(smallest) spacing(smaller) spacing(smaller);

    li {
        display: inline-block;
        padding: 0 spacing(smallest) 0 0;

        &.active {
            .tab {
                font-weight: font-weight(bolder);
                background-color: color(purple, darker);
                color: color(gray, white);
            }
        }

        .tab {
            height: auto;
            border-radius: 999px;
            color: color(gray, dark);
            padding: spacing(small) spacing(base);

            &:after {
                display: none;
            }

            &:hover {
                background-color: color(purple, dark);
                color: color(gray, white);
            }
        }
    }

    &.tab-size--small {
        li {
            .tab {
                font-size: font-size(base);
                padding: spacing(smaller) spacing(base);
            }
        }
    }
}

.scroller {
    position: absolute;
    z-index: 1;
    top: 0;
    width: input-height(base);
    height: 100%;
    padding: 0;
    border: none;
    color: color(gray, base);
    cursor: pointer;

    &--left {
        left: 0;
        text-align: left;
        background: linear-gradient(to right, color(gray, white) 50%, rgba(color(gray, white), 0))
    }

    &--right {
        right: 0;
        text-align: right;
        background: linear-gradient(to left, color(gray, white) 50%, rgba(color(gray, white), 0));
    }
}
