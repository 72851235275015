@import 'src/scss/tokens';

.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.close-button-container {
    flex: 0;
    padding: spacing(base) spacing(base);
    align-self: flex-start;
}

.container {
    background: color(gray, white);
    border-radius: border-radius(rounder) 0 0 border-radius(rounder);
    box-shadow: shadow(deep);
    flex: 1;
    height: 100%;
    overflow: auto;
    width: 100%;
}

.footer {
    box-shadow: shadow(base);
    flex: 0 0;
    z-index: 1;
    background-color: color(gray, white);
}
