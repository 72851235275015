@import 'src/scss/tokens';

.summary-footer {
    padding: spacing(small) 0;
    border-top: border-width(base) solid color(gray, lighter);
    background: color(gray, white);
}

.unsaved-changes {
    padding: spacing(small) spacing(base);
    border: border-width(base) solid color(gray, light);
    border-radius: border-radius(rounder);
    cursor: pointer;
    transition: border-color transition-duration(base)
        $transition-timing-function;

    &:hover {
        border-color: color(gray, dark);

        .view-settings {
            color: color(purple, darker);
        }
    }
}

.view-settings {
    vertical-align: middle;
    font-size: font-size(small);
    color: color(purple, dark);
    transition: color transition-duration(base) $transition-timing-function;
}

.summary {
    padding: spacing(base);
    border-radius: border-radius(rounder);
    background-color: rgba(color(gray, white), 0.75);
    backdrop-filter: blur(20px);
}

// TODO: Unify/simplify for all pill dropdowns
.dropdown {
    padding-top: spacing(smaller);
    padding-bottom: spacing(smaller);
}
