@import 'src/scss/tokens';
@import 'src/scss/mixins';

$nav-width: 275px;

.wrapper {
    display: none;
    position: sticky;
    top: 0;
    max-width: container-width(base);
    height: 0;
    margin: 0 auto;
    padding: 0 spacing(large);
}

.container {
    width: $nav-width;
    padding: spacing(large) 0;
}

.title {
    position: relative;
    display: block;
    padding-top: spacing(smaller);
    padding-bottom: spacing(smaller);
    color: color(gray, dark);
    transition: color transition-duration(base) $transition-timing-function;

    &:before {
        position: absolute;
        top: 0;
        left: 1px;
        content: '';
        width: border-width(thick);
        height: 100%;
        background-color: color(gray, lighter);
        transition: background-color transition-duration(base) $transition-timing-function;
    }

    &:visited {
        color: color(gray, dark);
    }
    
    &:hover {
        color: color(gray, darkest);

        &:before {
            background-color: color(gray, darkest);
        }
    }

}


.nav-item {
    > .title {
        padding-left: spacing(base);
        font-size: font-size(large);
    }

    &.active {
        > .title {
            font-weight: font-weight(bold);
            color: color(gray, darkest);
        }

        > .title {
            &:before {
                left: 0;
                width: border-width(thicker);
                border-radius: border-radius(base);
                background-color: color(purple, base);
            }
            &:hover {
                &:before {
                    background-color: color(gray, darkest);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .wrapper {
        display: block;
    }
}
