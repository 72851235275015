@import 'src/scss/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 257px;
    padding: spacing(large) 0;
}

.image-wrapper {
    flex-shrink: 0;
    margin-bottom: spacing(large);
}

.image {
    max-width: 100%;
    max-height: 289px;
}

@include media-breakpoint-up(lg) {
    .container {
        flex-direction: row-reverse;
    }

    .image-wrapper {
        height: 0;
        margin-bottom: 0;
        margin-left: spacing(large);
    }

    .image {
        transform: translateY(-50%);
    }
}
