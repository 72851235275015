@import 'src/scss/tokens';

.wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}

.container {
    flex: 1 0;
    overflow: hidden;
    position: relative;

    svg {
        display: block;
    }
}

.legend {
    flex: 0 0;
    svg {
        display: block;
    }
}
