@import 'src/scss/tokens';

.container {
    transition-property: background;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &--has-border-bottom {
        border-bottom: 1px solid color(gray, lightest);
    }

    &--has-border-top {
        border-top: 1px solid color(gray, lightest);
    }

    &--has-darker-border-bottom {
        border-bottom: 1px solid color(gray, lighter);
    }

    &--has-darker-border-top {
        border-top: 1px solid color(gray, lighter);
    }

    .background {
        &--centered {
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.container--primary {
    background: color(gray, white);
}

.container--secondary {
    background: color(gray, background);
}
