@import 'src/scss/tokens';

.tag-hint {
    display: flex;
    background-color: color(purple, lightest);
    border-radius: border-radius(rounder);
    padding: spacing(base);
    column-gap: spacing(base);
    align-items: center;

    .exclamation {
        font-size: 35px;
        line-height: 1;
        color: color(purple, base);
    }

    p {
        color: color(purple, darker);
        flex-grow: 1;
    }
}

.link {
    vertical-align: baseline;
    font-size: font-size(small);
    color: color(purple, dark);
    cursor: pointer;
}

.overview {
    display: flex;
    gap: spacing(base);
    align-items: center;
}

.overview-icon {
    width: 64px;
    height: 64px;
}

.overview-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: font-weight(bold);
    font-size: font-size(large);
}

.timeline {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: spacing(base);
        width: border-width(thick);
        background-color: color(purple, darker);
    }
}

.recommendation-wrapper {
    margin-top: spacing(base);
    margin-bottom: spacing(base);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
