@import 'src/scss/tokens';

.column {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: spacing(base);
    padding-bottom: spacing(base);
}

.recommendation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: spacing(small);
    padding: spacing(base);
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: spacing(small);
    padding: spacing(small) spacing(base);
    border: 2px dashed color(gray, lighter);
    border-radius: border-radius(rounder);
    color: color(gray, dark);

    p {
        margin-top: spacing(small);
        margin-bottom: spacing(small);
    }
}
