@import 'src/scss/tokens';

.container {
    display: flex;
    align-items: center;
    font-weight: font-weight(base);
    font-size: font-size(base);
    color: color(gray, dark);

    &.round {
        .count-label {
            border-radius: border-radius(rounded);
        }
    }

    &.square {
        .count-label {
            border-radius: border-radius(base);
        }
    }
}

.count-label {
    display: inline-block;
    height: 23px;
    margin-right: spacing(smallest);
    padding: 0 spacing(small);
    font-weight: font-weight(bold);
    line-height: 23px;
    color: color(gray, white);
    background-color: color(purple, darker);
}
