@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: border-radius(round);
    font-weight: font-weight(bold);
    line-height: line-height(base);
    font-family: font-family(base);
    text-align: center;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
    cursor: pointer;

    &:visited {
        color: color(gray, white);
    }

    &:focus {
        box-shadow: 0 0 0 spacing(smaller) rgba(color(purple, lighter), 0.75);
        outline: none;
    }

    &.smaller {
        height: input-height(smaller);
        padding-right: spacing(small);
        padding-left: spacing(small);
        border-radius: border-radius(base);
        font-size: font-size(smaller);

        .prefix {
            margin-right: spacing(smaller);
        }

        .suffix {
            margin-left: spacing(smaller);
        }
    }

    &.small {
        height: input-height(small);
        padding-right: spacing(small);
        padding-left: spacing(small);
        font-size: font-size(small);

        .prefix {
            margin-right: spacing(smaller);
        }

        .suffix {
            margin-left: spacing(smaller);
        }
    }

    &.base {
        height: input-height(base);
        padding-right: spacing(base);
        padding-left: spacing(base);
        font-size: font-size(base);
    }

    &.large {
        height: input-height(large);
        padding-right: spacing(base);
        padding-left: spacing(base);
        font-size: font-size(large);
    }

    &.square {
        max-width: min-content;
        padding-right: 0;
        padding-left: 0;

        &.smaller {
            min-width: input-height(smaller);
            font-size: font-size(small);
        }

        &.small {
            min-width: input-height(small);
            font-size: font-size(base);
        }

        &.base {
            min-width: input-height(base);
            font-size: font-size(large);
        }

        &.large {
            min-width: input-height(large);
            font-size: font-size(largest);
        }
    }

    &.primary {
        border-color: color(purple, base);
        color: color(gray, white);
        background-color: color(purple, base);

        &:active,
        &.active {
            border-color: color(purple, darker);
            background-color: color(purple, darker);
        }

        &:hover {
            border-color: color(purple, dark);
            background-color: color(purple, dark);
        }
    }

    &.outline {
        border-color: color(gray, light);
        color: color(purple, dark);
        background-color: color(gray, white);

        &:active,
        &.active {
            border-color: color(purple, darker);
            color: color(gray, white);
            background-color: color(purple, darker);
        }

        &:hover {
            border-color: color(purple, dark);
            color: color(gray, white);
            background-color: color(purple, dark);
        }
    }

    &.dark {
        border-color: color(gray, white);
        color: color(gray, white);
        background-color: color(purple, darkest);

        &:active,
        &.active {
            border-color: color(gray, white);
            color: color(purple, dark);
            background-color: color(gray, white);
        }

        &:hover {
            border-color: color(purple, dark);
            color: color(gray, white);
            background-color: color(purple, dark);
        }
    }

    &.light-blue {
        border-color: color(blue, light);
        color: color(gray, white);
        background-color: color(blue, light);

        &:active,
        &.active {
            border-color: color(blue, dark);
            background-color: color(blue, dark);
        }

        &:hover {
            border-color: color(blue, base);
            background-color: color(blue, base);
        }
    }

    &.switch {
        border-color: color(gray, light);
        color: color(purple, dark);
        background-color: color(gray, white);

        &:active,
        &.active {
            border-color: color(purple, darker);
            color: color(gray, white);
            background-color: color(purple, darker);
        }

        &:hover {
            border-color: color(purple, dark);
            color: color(gray, white);
            background-color: color(purple, dark);
        }

        .prefix,
        .suffix {
            opacity: 1;
            color: color(gray, dark);
            transition: color transition-duration(base) $transition-timing-function;
        }

        &:active,
        &.active,
        &:hover {
            .prefix,
            .suffix {
                color: color(gray, white);
            }
        }
    }

    &.select {
        border-color: color(gray, light);
        color: color(gray, darkest);
        background-color: color(gray, white);

        &:hover,
        &:focus {
            border-color: color(purple, dark);
        }
        .suffix {
            color: color(purple, dark);
        }
    }

    &.favorites {
        &:active,
        &.active {
            border-color: color(gray, light);
            color: color(purple, dark);
            background-color: color(gray, white);
        }

        &:hover {
            border-color: color(purple, dark);
            color: color(gray, white);
            background-color: color(purple, dark);
        }

        &[disabled] {
            opacity: 1;
            color: color(gray, dark);
        }
    }

    &.calendar {
        border-color: color(gray, lightest);
        color: color(gray, dark);
        background-color: color(gray, lightest);

        &:active,
        &.active {
            border-color: color(purple, darker);
            color: color(gray, white);
            background-color: color(purple, darker);
        }

        &:hover {
            border-color: color(purple, dark);
            color: color(gray, white);
            background-color: color(purple, dark);
        }
    }

    &.droparea {
        height: auto;
        padding: spacing(base);
        border: border-width(thick) dashed color(purple, base);
        font-weight: normal;
        line-height: normal;
        color: color(gray, dark);
        background-color: transparent;
        transition-property: border-color, background-color, color;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;

        .inner {
            flex-direction: column;
        }

        .prefix,
        .suffix {
            color: color(purple, dark);
            transition: color transition-duration(base) $transition-timing-function;
        }

        .prefix {
            padding-bottom: spacing(smaller);
        }

        .suffix {
            padding-top: spacing(smaller);
        }

        &:focus,
        &:hover,
        &:active,
        &.active {
            border-color: color(purple, darker);
            color: color(purple, darker);
            background-color: color(purple, lightest);

            .prefix,
            .suffix {
                color: color(purple, darker);
            }
        }

        &:focus {
            background-color: transparent;
        }
    }

    &.error {
        border-color: color(red, dark);
    }
}

.inner {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    @include ellipsis();
}
.select .content {
    display: block;
    text-align: left;
    font-weight: font-weight(base);
    @include ellipsis();
}

.prefix,
.suffix {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: font-weight(base);
    opacity: 0.8;
}

.prefix {
    margin-right: spacing(small);
}

.suffix {
    margin-left: spacing(small);
}

.tooltip {
    width: 250px;
    padding: spacing(base);

    &--fluid {
        width: auto;
        padding: 0;
    }
}

[disabled] {
    opacity: 0.7;
    filter: saturate(0);
    pointer-events: none;
}

[hidden] {
    visibility: hidden;
}
