@import 'src/scss/tokens';

.container {
    z-index: 9999;
    position: relative;
}

.is-fixed {
    bottom: 0;
    display: flex;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateZ(0); // This is to attempt to improve scrolling performance of items placed inside the layer.
}

.translucent-background {
    background: rgba(color(gray, white), 0.85);
}

.no-scroll {
    overflow: hidden;
}
