@import 'src/scss/tokens';

.logo {
    width: 50px;
    margin-left: spacing(small);
    float: right;
}

.container {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{spacing(large)} * 2);
    overflow: hidden;
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
    box-shadow: shadow(deep);
}

.content {
    display: flex;
    flex-direction: column;
    max-height: 605px;
    min-height: 0;
    white-space: pre-line;
}

.buttonBar {
    display: flex;
    flex-direction: column;
    padding: spacing(small) spacing(large);
    position: relative;
    box-shadow: shadow(base);

    .acceptButton,
    .scrollDownButton {
        transition: opacity transition-duration(base) $transition-timing-function;
    }

    .acceptButton {
        opacity: 0;
        pointer-events: none;
        align-self: flex-end;
    }

    .scrollDownButton {
        width: fit-content;
        position: absolute;
        top: (0 - spacing(base));
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    &.isBottom {
        .acceptButton {
            opacity: 1;
            pointer-events: auto;
        }

        .scrollDownButton {
            opacity: 0;
            pointer-events: none;
        }
    }
}
