@import 'src/scss/tokens';

.centered {
    display: flex;
    justify-content: center;
}

.container {
    display: inline-flex;
    align-items: center;
    font-weight: font-weight(bolder);

    &.large {
        font-size: font-size(largest);
    }

    &.base {
        font-size: font-size(large);
    }

    &.small {
        font-weight: font-weight(bold);
        font-size: font-size(base);

        .icon-wrapper {
            font-size: 1.27em;
        }

        &.bottom {
            .message {
                margin-top: spacing(smaller);
            }
        }

        &.left {
            .message {
                margin-right: spacing(smaller);
            }
        }

        &.top {
            .message {
                margin-bottom: spacing(smaller);
            }
        }

        &.right {
            .message {
                margin-left: spacing(smaller);
            }
        }
    }

    &.bottom {
        flex-direction: column;

        .message {
            margin-top: spacing(small);
        }
    }

    &.right {
        flex-direction: row;

        .message {
            margin-left: spacing(small);
        }
    }

    &.top {
        flex-direction: column-reverse;

        .message {
            margin-bottom: spacing(small);
        }
    }

    &.left {
        flex-direction: row-reverse;

        .message {
            margin-right: spacing(small);
        }
    }
}

.icon-wrapper {
    font-size: 1.265em;
    line-height: 1;
}

.icon {
    display: inline-block;
    height: 1em;
    width: auto;
    vertical-align: top;

    > svg {
        animation: spin 0.5s infinite linear;
        display: inline-block;
        vertical-align: top;
        fill: currentColor;
        height: 100%;
        overflow: visible;
        transform-origin: center;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
