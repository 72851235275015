@import 'src/scss/tokens';

.wrapper {
    display: inline-block;
    width: 100%;

    &.empty {
        width: 0;
    }
}

.content {
    opacity: 1;

    &.invisible {
        opacity: 0;
    }
}

.popper--theme-tooltip {
    border-radius: border-radius(rounder);
    color: color(gray, dark);
    background-color: color(gray, white);
    box-shadow: shadow(deep);

    p + p {
        margin-top: spacing(small);
    }
}

.popper--width-fluid {}

.popper--width-fixed {
    width: 250px;
}

.popper--width-same {}
