@import 'src/scss/mixins';
@import 'src/scss/tokens';

.dropdown-option {
    margin: spacing(small);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .icon {
        margin-right: spacing(small);
        color: color(gray, darkest);
        transition: color transition-duration(base) $transition-timing-function;
    }

    .content {
        display: flex;
        padding: spacing(small);
        border: border-width(base) solid color(gray, light);
        border-radius: border-radius(round);
        font-weight: font-weight(bold);
        color: color(gray, dark);
        cursor: pointer;
        transition: border-color, color;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;

        &:hover {
            border-color: color(purple, dark);
            color: color(purple, dark);

            .icon {
                color: color(purple, dark);
            }
        }
    }

    &.selected {
        .content {
            border-color: color(purple, darker);
            color: color(purple, darker);

            .icon {
                color: color(purple, darker);
            }
        }

        &:hover {
            .content {
                border-color: color(purple, dark);
                color: color(purple, dark);
            }

            .icon {
                color: color(purple, dark);
            }
        }
    }

    .label {
        width: 100%;
        @include ellipsis();
    }
}
