@import 'src/scss/tokens';

.container {
    border-radius: border-radius(rounder);

    &.bordered {
        border: 1px solid color(gray, lightest);
    }
}

.wrapper {
    position: relative;
    z-index: 1;
}

.primary {
    background: color(gray, white);
}

.secondary {
    background: color(gray, background);
}

.tertiary {
    background: color(purple, lightest);
}
