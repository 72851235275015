@import 'src/scss/tokens';

.label-container {
    align-items: flex-end;
    display: flex;
}

.label {
    font-size: font-size(large);
    font-weight: font-weight(bold);

    &--cursor-pointer {
        cursor: pointer;
    }

    &--error {
        color: color(red, dark);
    }
}

.info-container {
    padding: 0 0 spacing(smallest) spacing(smaller);
}

.required-label {
    color: color(gray, dark);
    font-size: font-size(smaller);
    font-weight: font-weight(base);
    padding-left: spacing(smaller);
    margin-bottom: spacing(smallest);
    text-transform: uppercase;
}

.hint-container {
    > p {
        color: color(gray, dark);
        font-size: font-size(base);
        line-height: line-height(large);

        & + p {
            margin-top: spacing(small);
        }

        * {
            line-height: inherit;
        }
    }
}

.error-message-container {
    margin-top: spacing(small);

    > p {
        color: color(red, dark);
        font-size: font-size(base);
        line-height: line-height(large);

        & + p {
            margin-top: spacing(small);
        }

        &:last-child {
            margin: 0;
        }
    }
}
