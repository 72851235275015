@import 'src/scss/tokens';

.container {
    width: 100%;
    display: flex;
    align-items: stretch;
    position: relative;

    textarea {
        width: 100%;
        height: auto;
        max-height: none;
        margin: 0;
        padding: spacing(small);
        border: border-width(base) solid color(gray, light);
        border-radius: border-radius(round);
        font-size: font-size(base);
        color: color(gray, darkest);
        resize: vertical;
        background: color(gray, white);
        transition: border-color, box-shadow;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;

        &:hover {
            border-color: color(purple, dark);
        }

        &:focus {
            border-color: color(purple, darker);
            outline: none;
            box-shadow: 0 0 0 spacing(smaller) rgba(color(purple, lighter), 0.75);
        }

        &::placeholder {
            color: color(gray, light);
        }
    }

    &.is-active {
        textarea {
            border-color: color(purple, base);

            &:focus {
                border-color: color(purple, darker);
            }
        }
    }
}
