@import 'src/scss/mixins';
@import 'src/scss/tokens';

$side-spacing-narrow-screen: spacing(smallest);
$side-spacing-wide-screen: spacing(smaller);

.container {
    display: flex;
    margin-right: -($side-spacing-narrow-screen);
    margin-left: -($side-spacing-narrow-screen);

    .step {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-width: 0;
        position: relative;
        padding: 0 $side-spacing-narrow-screen spacing(small);
        font-size: font-size(small);
        color: color(gray, dark);
        cursor: pointer;
        pointer-events: none;
        transition: color transition-duration(base) $transition-timing-function;

        &:after {
            position: absolute;
            right: $side-spacing-narrow-screen;
            bottom: 0;
            left: $side-spacing-narrow-screen;
            content: '';
            height: border-width(thicker);
            background-color: color(gray, lighter);
            transition: background-color transition-duration(base) $transition-timing-function;
        }

        &:first-child {
            &:after {
                border-radius: border-radius(base) 0 0 border-radius(base);
            }
        }

        &:last-child {
            &:after {
                border-radius: 0 border-radius(base) border-radius(base) 0;
            }
        }

        &.completed {
            font-weight: font-weight(bold);
            color: color(purple, darker);
            pointer-events: auto;

            &:after {
                background-color: color(purple, darker);
            }
        }

        &.active {
            font-weight: font-weight(bold);
            color: color(gray, darkest);

            &:after {
                background-color: color(purple, base);
            }
        }

        &:hover {
            color: color(purple, dark);

            &:after {
                background-color: color(purple, dark);
            }
        }
    }
}

.label {
    @include ellipsis();
}

.icon {
    display: none;
}

@include media-breakpoint-up(sm) {
    .container {
        margin-right: -($side-spacing-wide-screen);
        margin-left: -($side-spacing-wide-screen);

        .step {
            padding-right: $side-spacing-wide-screen;
            padding-left: $side-spacing-wide-screen;
            font-size: font-size(base);

            &:after {
                right: $side-spacing-wide-screen;
                left: $side-spacing-wide-screen;
            }
        }
    }

    .icon {
        display: inline;
    }
}
