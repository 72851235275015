@import 'src/scss/mixins';
@import 'src/scss/tokens';

.wrapper {
    display: inline-block;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding-top: spacing(small);
    padding-bottom: spacing(small);

    &--same-width {
        min-width: auto;
    }

    &--searchable {
        height: 250px;
    }
}

.search-form {
    margin-bottom: spacing(small);
    padding: 0 spacing(small) spacing(small);
    border-bottom: border-width(base) solid color(gray, lightest);
}

.sorting {
    padding: 0 spacing(small) spacing(small);
}

.sorting-option {
    color: color(purple, dark);
    cursor: pointer;

    &.selected {
        font-weight: font-weight(bold);
        color: color(gray, darkest);
        pointer-events: none;
    }
}

.list {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-height: 385px;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.item {
    margin-top: spacing(smallest);
    margin-bottom: spacing(smallest);
    padding-right: spacing(small);
    padding-left: spacing(small);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.button {
    margin: spacing(small);
    padding-right: 0;
    padding-left: 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.divider {
    margin-top: spacing(smaller);
    margin-bottom: spacing(smaller);
    border-bottom: 1px solid color(gray, lighter);
}

.title {
    padding: spacing(small) spacing(base);
    color: color(gray, dark);
}

.icon {
    margin-right: spacing(smaller);
    color: color(gray, darkest);
    transition: color transition-duration(base) $transition-timing-function;
}

.label {
    width: 100%;
    @include ellipsis();
}

.item-hint {
    display: block;
    max-width: 250px;
    font-weight: font-weight(base);
    font-size: font-size(small);
    color: color(gray, dark);
    transition: color transition-duration(base) $transition-timing-function;
}

.item-content {
    display: flex;
    flex-wrap: wrap;
    padding: spacing(small);
    border-radius: border-radius(round);
    font-weight: font-weight(bold);
    color: color(purple, dark);
    cursor: pointer;
    transition: background, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &:active {
        color: color(gray, white);
        background: color(purple, darker);
    }

    &:hover {
        color: color(gray, white);
        background: color(purple, dark);

        .icon,
        .item-hint {
            color: color(gray, white);
        }
    }
}

.selected .item-content {
    color: color(gray, white);
    background: color(purple, darker);
    transition: background transition-duration(base) $transition-timing-function;

    .icon,
    .item-hint {
        color: color(gray, white);
    }
}

.error {
    padding-right: spacing(base);
    padding-left: spacing(base);
}

.container--theme-search {
    width: 100vw;
    max-width: search-width(base);
    max-height: 80vh;
    overflow: auto;
    padding: spacing(small) 0;

    .item {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 0;
        padding-left: 0;
        border-bottom: border-width(base) solid color(gray, lightest);

        &:hover .tooltip {
            z-index: 1;
            opacity: 1;
            transition-delay: 0s, 0.6s;
        }

        &:last-child .tooltip {
            top: auto;
            bottom: spacing(small) + spacing(base);
        }
    }

    .title {
        margin: 0;
        padding: spacing(small) spacing(base);
        color: color(gray, black);
    }

    * + .title {
        margin-top: spacing(base);
    }

    .link {
        &--show-more {
            font-style: italic;

            .item-content {
                font-weight: font-weight(base);
                color: color(gray, dark);
            }
        }
    }

    .item-content {
        margin: 0;
        padding: spacing(small) spacing(base);
        border-radius: border-radius(none);
        color: color(gray, black);

        &:hover {
            background: color(gray, background);
            color: color(gray, black);
        }
    }

    .tooltip {
        position: absolute;
        top: spacing(small) + spacing(base);
        left: spacing(large);
        z-index: -1;
        opacity: 0;
        padding: spacing(small) spacing(base);
        border-radius: border-radius(rounder);
        font-weight: font-weight(base);
        background-color: color(gray, white);
        box-shadow: shadow(deep);
        cursor: default;
        transition-property: z-index, opacity;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;
    }
}

// Keep in sync with search-bar.module.scss
@include media-breakpoint-up(sm) {
    .container--theme-search {
        width: search-width(smallest);
    }
}

@include media-breakpoint-up(md) {
    .container--theme-search {
        width: search-width(small);
    }
}

@include media-breakpoint-up(lg) {
    .container--theme-search {
        width: search-width(base);
    }
}
