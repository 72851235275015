@import 'src/scss/tokens';

.table-container {
    display: table;
    width: 100%;

    &.table--columns-first-fluid-width .table-cell:first-of-type {
        width: 100%;
    }

    &.table--columns-first-fixed-width {
        .table-row--is-header .table-cell {
            border-bottom: border-width(base) solid color(gray, lightest);
        }

        .table-cell:first-of-type {
            min-width: 300px;
            border-right: border-width(base) solid color(gray, lightest);
        }
    }

    &.table--columns-first-sticky .table-cell:first-of-type {
        position: sticky;
        left: 0;
        background-color: color(gray, white);
    }

    &.table--columns-last-fluid-width .table-cell:last-of-type {
        width: 100%;
    }

    &.table--columns-no-wrap .table-row--is-header .table-cell {
        white-space: nowrap;
    }

    &.table--vertically-centered .table-row:not(.table-row--is-header) .table-cell {
        vertical-align: middle;
    }
}

.table-row {
    display: table-row;

    .table-cell {
        display: table-cell;
        padding: spacing(small) spacing(small);

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }

    &--has-border .table-cell {
        border-bottom: border-width(base) solid color(gray, lightest);
    }
}
