@import 'src/scss/tokens';

.spinner-container {
    width: 100%;
    text-align: center;
}

.result-text {
    display: inline-block;
    padding: spacing(smaller);
}

.has-data-icon {
    display: inline-block;
    padding-left: spacing(small);
    color: color(purple, base);
    height: 100%;
    font-size: font-size(largest);
}

.result-button-container {
    display: inline-block;
    padding-left: spacing(small);
}

.line-item-dropdown {
    display: inline-block;
    margin-left: spacing(base);
    color: color(purple, dark);
    padding: spacing(smaller);
}

.has-data-tooltip {
    width: 175px;
    padding: spacing(base);
}
