@import 'src/scss/tokens';
@import 'src/scss/mixins';

.line-through {
    display: none;
}

@include media-breakpoint-up(sm) {
    .line-through {
        display: flex;
        background-color: color(gray, lightest);
        height: 2px;
        border: 0;
    }
}
