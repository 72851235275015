@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 288px;
    overflow: hidden;
    padding: spacing(base);
    border-radius: border-radius(rounder);
    background: color(blue, lightest);
    color: color(gray, darkest);

    @each $colorKey in map-keys($color) {
        @each $tone in map-keys(map-get($color, $colorKey)) {
            &.background--#{$colorKey}-#{$tone} {
                background: color($colorKey, $tone);
            }
        }
    }

    > img {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;

        &.background {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &.logo {
            height: 350px;
            margin: 0 auto;
            top: calc((288px - 350px) / 2);

            &.align--mid-left {
                margin-left: 10%;
            }
            &.align--mid-right {
                margin-right: 10%;
            }
        }
    }

    .favorite {
        position: absolute;
        top: spacing(base);
        right: spacing(base);
        z-index: 3;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.0;
        background-color: rgba(color(gray, black), 0.4);
        transition: opacity transition-duration(base) $transition-timing-function;
        z-index: 1;
    }
    &:hover::after {
        opacity: 1.0;
    }

    &.shadow {
        box-shadow: shadow(base);
        transition: box-shadow transition-duration(base) $transition-timing-function;

        &::after {
            display: none;
        }
        &:hover {
            box-shadow: shadow(deep);
        }
    }

    .content {
        z-index: 2;

        &:not(.overlay) {
            align-self: flex-start;
            max-width: 520px + spacing(large);
        }
        &.overlay {
            align-self: flex-end;
            width: 100%;
            padding: spacing(base);
            border-radius: border-radius(rounder);
            background: rgba(color(gray, white), 0.75);
            backdrop-filter: blur(20px);
        }
    }
}
