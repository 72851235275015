@import 'src/scss/tokens';

.content {
    &.active,
    &:active {
        .icon {
            background-color: color(gray, white);

            p {
                color: color(purple, darker);
            }
        }
    }

    &:hover {
        .icon {
            background-color: color(gray, white);

            p {
                color: color(purple, dark);
            }
        }
    }

}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: font-size(base);
    margin: 0 spacing(smaller) 0 spacing(base);
}

.icon {
    margin: 0 spacing(smaller);
    display: flex;
    width: 27px;
    height: 27px;
    background-color: color(purple, base);
    border-radius: border-radius(base);
    justify-content: center;
    transition: background-color transition-duration(base) $transition-timing-function;

    p {
        color: color(gray, white);
        font-size: font-size(base);
        padding-top: spacing(smaller);
        transition: color transition-duration(base) $transition-timing-function;
    }
}
