@import 'src/scss/tokens';

@mixin clearfix() {
  &::before,
	&::after {
		content: "";
		display: table;
	}
	&::after {
		clear: both;
	}
}

@function breakpoint-prefix($name) {
  @return if(breakpoint($name) == 0, '', '#{$name}-');
}

@mixin media-breakpoint-up($name) {
  $width: breakpoint($name);
  @if $width != 0 {
      @media (min-width: $width) {
          @content;
      }
  } @else {
      @content;
  }
}

@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
