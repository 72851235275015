@use 'sass:math';
@import 'src/scss/mixins';
@import 'src/scss/tokens';

$grid-columns: 12;

.row {
    display: flex;
    flex-wrap: wrap;

    &--reverse {
        flex-wrap: wrap-reverse;
    }
}

.overflow--hidden {
    overflow: hidden;
}

@each $size in map-keys($breakpoint) {
    $prefix: breakpoint-prefix($size);

    @include media-breakpoint-up($size) {
        // Single Column Vertical Alignment
        .#{$prefix}col--align-self-flex-start {
            align-self: flex-start;
        }

        .#{$prefix}col--align-self-center {
            align-self: center;
        }

        .#{$prefix}col--align-self-flex-end {
            align-self: flex-end;
        }

        // All Columns In Row Vertical Alignment
        .#{$prefix}row--align-items-flex-start {
            align-items: flex-start;
        }

        .#{$prefix}row--align-items-center {
            align-items: center;
        }

        .#{$prefix}row--align-items-flex-end {
            align-items: flex-end;
        }

        .#{$prefix}row--align-items-stretch {
            align-items: stretch;
        }

        // All Columns In Row Horizontal Alignment/Spacing

        .#{$prefix}row--justify-content-flex-start {
            justify-content: flex-start;
        }

        .#{$prefix}row--justify-content-flex-end {
            justify-content: flex-end;
        }

        .#{$prefix}row--justify-content-center {
            justify-content: center;
        }

        .#{$prefix}row--justify-content-space-between {
            justify-content: space-between;
        }

        .#{$prefix}row--justify-content-space-around {
            justify-content: space-around;
        }

        .#{$prefix}row--justify-content-start {
            justify-content: space-evenly;
        }

        @each $gutterSize in map-keys($spacing) {
            .#{$prefix}row--gutter-#{$gutterSize} {
                margin-right: 0 - (spacing($gutterSize) * 0.5);
                margin-left: 0 - (spacing($gutterSize) * 0.5);

                > .col {
                    padding-right: spacing($gutterSize) * 0.5;
                    padding-left: spacing($gutterSize) * 0.5;
                }
            }
        }

        @each $gapSize in map-keys($spacing) {
            .#{$prefix}row--gap-#{$gapSize} {
                row-gap: spacing($gapSize)
            }
        }

        .#{$prefix}col {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            min-height: 1px; // To prevent columns from collapsing when empty
            width: 100%;
        }

        .#{$prefix}col--auto {
            flex: 0 0 auto;
            max-width: 100%;
            width: auto;
        }

        .#{$prefix}col--equal {
            flex-basis: 0;
            flex-grow: 1;
            width: 0;
            max-width: 100%;
        }

        @for $size from 1 through $grid-columns {
            .#{$prefix}col--#{$size} {
                flex: 0 0 percentage(math.div($size, $grid-columns));
                max-width: percentage(math.div($size, $grid-columns));
            }
        }

        @for $i from 0 through ($grid-columns - 1) {
            @if not($prefix == '' and $i == 0) {
                $num: math.div($i, $grid-columns);
                .#{$prefix}col--offset-#{$i} {
                    margin-left: if($num == 0, 0, percentage($num));
                }
            }
        }

        .#{$prefix}col--order-first {
            order: -1;
        }

        .#{$prefix}col--order-last {
            order: $grid-columns + 1;
        }

        @for $i from 0 through $grid-columns {
            .#{$prefix}col--order-#{$i} {
                order: $i;
            }
        }

    }
}
