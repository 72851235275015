@import 'src/scss/mixins';
@import 'src/scss/tokens';

$navWidth: 21px;

.container :global {
    .image-gallery {
        max-height: 450px;
        padding: spacing(base);
        background-color: color(gray, background);
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
        .image-gallery-svg {
            height: 35px;
            width: $navWidth;
        }

        &[disabled] {
            opacity: 0;
        }
    }

    .image-gallery-slides {
        margin-left: -(spacing(base));
        margin-right: -(spacing(base));
        text-align: right;
    }

    .image-gallery-slide {
        padding-right: spacing(large) + $navWidth;
        padding-left: spacing(large) + $navWidth;
    }

    .image-gallery-bullets {
        position: static;
        padding-top: spacing(small);

        .image-gallery-bullet {
            margin-right: spacing(smaller);
            margin-left: spacing(smaller);
            padding: 6px;
            border: none;
            background: color(gray, white);
            box-shadow: none;

            &.active {
                background: color(gray, lighter);
            }

            &:focus,
            &:hover {
                background: color(gray, light);
                transform: none;
            }
        }
    }
}

.slide {
    position: relative;
    min-height: 356px; // TODO: in case of no `spotlight_image`
}

.slide-image {
    margin-right: -(spacing(large) + $navWidth);
    margin-left: -(spacing(large) + $navWidth);

    img {
        max-width: 100%;
        height: 355px;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.panel-wrapper {
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    white-space: normal;
}

.panel {
    padding: spacing(base);
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
    backdrop-filter: blur(20px);
}

.logo {
    height: 40px;
}

@include media-breakpoint-up(md) {
    .container :global {
        .image-gallery-slides {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .slide-image {
        margin-left: 0;
        margin-right: 0;
    }

    .panel {
        max-width: 250px;
    }
}

@include media-breakpoint-up(lg) {
    .panel {
        max-width: 500px;
    }
}
