@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container :global {
    .image-gallery-icon {
        color: color(gray, lighter);
        filter: none;

        &:hover {
            color: color(gray, light);

            .image-gallery-svg {
                transform: none;
            }
        }
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
        padding: 0;

        .image-gallery-svg {
            height: 100px;
            width: 50px;
        }

        &[disabled] {
            opacity: 0;
        }
    }

    .image-gallery-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        z-index: 0;
        outline: none;
        transform: translate3d(0, 0, 0) !important;
        transition: opacity transition-duration(slower) linear !important;

        &.center {
            opacity: 1;
            z-index: 1;
        }
    }

    .image-gallery-bullets {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        .image-gallery-bullets {
            display: block;
            bottom: 30px;

            .image-gallery-bullet {
                margin-right: spacing(smaller);
                margin-left: spacing(smaller);
                padding: 4px;
                border: none;
                border-radius: 50%;
                background-color: color(gray, lighter);
                box-shadow: none;

                &:focus,
                &:hover {
                    background: color(purple, base);
                    transform: none;
                }

                &.active {
                    background: color(purple, base);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .image-gallery-bullets {
            .image-gallery-bullet {
                padding: 6px;
            }
        }
    }
}
