@import 'src/scss/tokens';
@import 'src/scss/mixins';

.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    overflow: auto;
    padding: spacing(smaller) spacing(base);
    background-color: color(purple, darker);

    &.unsafe {
        background-color: color(red, dark);
    }
}

.top-container {
    display: flex;
    align-items: center;
    padding: spacing(smallest) 0;
}

.logo-container {
    margin-right: spacing(base);
}

.logo {
    height: 48px;
    transform-origin: 50%;
    transition: transform transition-duration(base) $transition-timing-function;

    &:hover {
        transform: scale(1.1);
    }
}

.link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    margin-right: spacing(smallest);
    border-radius: border-radius(round);
    cursor: pointer;
    transition: background-color, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &.active,
    &:hover {
        .hover-hidden {
            opacity: 0;
        }

        .hover-show {
            opacity: 1;
        }
    }

    &.active {
        background-color: color(gray, white);

        .icon-wrapper,
        .name {
            color: color(purple, darker);
        }
    }


    &:hover {
        background-color: color(purple, dark);

        .icon-wrapper,
        .name {
            color: color(gray, white);
        }
    }
}

.icon-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 21px;
    font-size: font-size(largest);
    color: color(gray, white);
}

.icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity transition-duration(base) $transition-timing-function;

    &.hover-show {
        opacity: 0;
    }
}

.name {
    margin-top: spacing(smaller);
    font-size: font-size(smaller);
    color: color(gray, white);
    white-space: nowrap;
    text-transform: uppercase;
    transition: color transition-duration(base) $transition-timing-function;
}

.account-logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    margin-left: spacing(base);
    border-radius: border-radius(round);
    background-color: color(gray, white);
}

.account-logo {
    max-width: 28px;
    max-height: 28px;
}

.bg {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.selected-account-logo {
    width: 35px;
    height: 35px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.selected-account-no-logo {
    width: 35px;
    height: 35px;
    border-radius: border-radius(round);
    border: border-width(base) dashed color(gray, lighter);
}

.inactive-account-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: color(yellow, base);
    z-index: 1000;
}


@include media-breakpoint-up(sm) {
    .container {
        padding-right: spacing(large);
        padding-left: spacing(large);
    }
}

@include media-breakpoint-up(md) {
    .container {
        flex-direction: column;
        padding: spacing(base) spacing(smaller);
    }

    .top-container {
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
    }

    .logo-container {
        margin-right: 0;
        margin-bottom: spacing(large);
    }

    .link {
        margin-right: 0;
        margin-bottom: spacing(smallest);
    }

    .account-logo-container {
        margin: spacing(base) 0;
    }

    .bg {
        display: block;
    }
}
