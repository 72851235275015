@import 'src/scss/tokens';
@import 'src/scss/mixins';

.view {
    background-color: color(gray, background);
}

.feature-heading {
    font-size: 60px;
    font-weight: 200;
}

.overview {
    font-size: font-size(large);

    h1, h2, h3, h4, h5, h6 {
        margin-top: spacing(base);
        margin-bottom: spacing(small);
        font-weight: font-weight(bolder);
        font-size: font-size(larger);
    }

    ul {
        list-style: disc;
        margin-left: spacing(base);
    }

    ul + p,
    p + p {
        margin-top: spacing(base);
    }
}

.feature-image-container {
    background-color: color(gray, background);
    border-radius: border-radius(rounder);
    margin: spacing(large) 0;
    padding: spacing(large) spacing(base);
}

.feature-image img {
    width: 100%;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 18px;
    width: 250px;
    background: color(gray, white);
    box-shadow: shadow(deep);
    border-radius: border-radius(rounder);
}

.logo-img-container {
    height: 35px;
}


@include media-breakpoint-up(sm) {
    .feature-image-container {
        padding-left: spacing(large);
        padding-right: spacing(large);
    }
}

@include media-breakpoint-up(md) {
    .feature-image-container {
        padding-left: spacing(larger);
        padding-right: spacing(larger);
    }
}

@include media-breakpoint-up(lg) {
    .container {
        align-items: stretch;
        display: flex;
        flex: 1;
        height: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        overflow-x: hidden;
    }

    .feature-image-container {
        padding-left: spacing(larger) * 2;
        padding-right: spacing(larger) * 2;
    }
}
