@import 'src/scss/tokens';

.content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 18px;
    width: 250px;
    background: color(gray, white);
    box-shadow: shadow(deep);
    border-radius: border-radius(rounder);
}
