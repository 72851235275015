@import 'src/scss/tokens';

.clear-button-container {
    position: absolute;
    top: 50%;
    right: spacing(small);
    transform: translate(0, -50%);

    button {
        background: none;
        border: none;
        color: color(purple, dark);
        cursor: pointer;

        &:hover {
            color: color(purple, darker);
        }
    }
}

.container {
    width: 100%;
    display: flex;
    align-items: stretch;
    position: relative;

    > input {
        background: color(gray, white);
        border: 1px solid color(gray, light);
        border-radius: border-radius(round);
        color: color(gray, darkest);
        font-size: font-size(base);
        height: input-height(base);
        margin: 0;
        max-height: input-height(base);
        order: 1;
        padding: 0 spacing(base);
        transition: border-color, box-shadow;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;
        width: 100%;

        &:hover {
            border-color: color(purple, dark);

            + .prefix {
                border-color: color(purple, dark);
            }
        }

        &:focus {
            outline: none;
            border-color: color(purple, darker);
            box-shadow: 0 0 0 spacing(smaller)
                rgba(color(purple, lighter), 0.75);

            + .prefix {
                color: color(purple, darker);
                border-color: color(purple, darker);
                box-shadow: (0 - spacing(smaller)) spacing(smaller) 0 0
                        rgba(color(purple, lighter), 0.75),
                    (0 - spacing(smaller)) (0 - spacing(smaller)) 0 0
                        rgba(color(purple, lighter), 0.75);
            }

            .clear-button-container {
                color: color(purple, darker);
            }
        }

        &::placeholder {
            color: color(gray, light);
        }

        &.has-prefix {
            padding-left: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-left: none;
        }

        &.has-clear-button {
            padding-right: (
                spacing(small) + input-height(small) + spacing(small)
            );
        }
    }

    > .prefix {
        align-items: center;
        border: 1px solid color(gray, light);
        background: white;
        border-right-width: 0;
        color: color(purple, dark);
        display: flex;
        justify-content: center;
        min-width: input-height(base);
        padding: 0 spacing(small) 0 spacing(base);
        position: relative;
        transition: background-color, border-color, box-shadow;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;
        white-space: nowrap;
        z-index: 1;
        border-radius: border-radius(round) 0 0 border-radius(round);
        order: 0;

        &:hover {
            color: color(purple, darker);
        }
    }

    &.has-error {
        > input {
            border-color: color(red, dark);

            &:hover {
                border-color: color(red, dark);

                + .prefix {
                    border-color: color(red, dark);
                }
            }

            &:focus {
                border-color: color(purple, darker);
                box-shadow: 0 0 0 spacing(smaller)
                    rgba(color(purple, lighter), 0.75);

                + .prefix {
                    border-color: color(purple, darker);
                    box-shadow: (0 - spacing(smaller)) spacing(smaller) 0 0
                            rgba(color(purple, lighter), 0.75),
                        (0 - spacing(smaller)) (0 - spacing(smaller)) 0 0
                            rgba(color(purple, lighter), 0.75);
                }
            }
        }

        > .prefix {
            border-color: color(red, base);
        }
    }

    &.is-active {
        > input {
            border-color: color(purple, base);

            &:focus {
                border-color: color(purple, darker);

                + .prefix {
                    border-color: color(purple, darker);
                }
            }
        }

        > .prefix {
            border-color: color(purple, base);
        }
    }
}

.image-container {
    img {
        max-width: 100%;
        max-height: 100px;
    }
}

.error-message-container {
    padding: spacing(small) 0 0;

    > p {
        color: color(red, dark);
        font-size: font-size(base);
        line-height: line-height(large);
        margin: 0 0 spacing(small);

        &:last-child {
            margin: 0;
        }
    }
}

.link {
    vertical-align: baseline;
    font-size: font-size(small);
    color: color(purple, dark);
    cursor: pointer;
}
