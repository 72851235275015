@use 'sass:math';
@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container,
.form {
    display: flex;

    &--align-left {
        justify-content: flex-start;
    }

    &--align-right {
        justify-content: flex-end;
    }
}

.form {
    position: relative;
    height: input-height(base);
}

.button {
    position: relative;
    z-index: 1;
    transition-property: background-color, border-color, box-shadow, color, z-index;
    transition-duration: transition-duration(base), transition-duration(base), transition-duration(base), transition-duration(base), 0s;
    transition-timing-function: $transition-timing-function;
    transition-delay: 0s, 0s, 0s, 0s, transition-duration(base);

    &--hidden {
        z-index: 0;
        transition-property: background-color, border-color, box-shadow, color, z-index;
        transition-delay: 0s;
    }
}

.input {
    position: absolute;
    width: input-height(base);
    transition: width transition-duration(base) $transition-timing-function;

    &--expanded {
        width: calc(100vw - #{spacing(base)} * 2); // Clumsy attempt to fit under the mobile environment

        &.input--with-overlap {
            &:before {
                content: "";
                position: absolute;
                right: 100%;
                top: math.div(input-height(base) - input-height(large), 2);
                width: input-height(large);
                height: input-height(large);
                background: linear-gradient(to left, color(gray, white), rgba(color(gray, white), 0));

                .form--align-left & {
                    right: 0;
                    left: 100%;
                    background: linear-gradient(to right, color(gray, white), rgba(color(gray, white), 0));
                }
            }
        }
    }
}

.error {
    padding: spacing(small) spacing(base);
    color: color(gray, dark);

    &--fatal {
        padding: spacing(small) spacing(base);
        color: color(red, base);
    }
}

.action-items__wrapper {
    margin-top: spacing(small);
}

.action-items__container {
    display: flex;
    justify-content: flex-end;
}

.action-items__inner {
    display: inline-block;
}

.logo {
    width: 35px;
    height: 35px;
    margin-right: spacing(small);
}

.label-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.label-wrapper {
    min-width: 0;
}

.label {
    @include ellipsis();
}

.icon-wrapper {
    flex-shrink: 0;
    margin-left: spacing(base);
}

.icon {
    display: flex;
    color: color(red, dark);

    &.active {
        color: color(purple, base);
    }

    svg {
        font-size: font-size(larger);
    }
}

// Keep in sync with dropdown-menu.module.scss
@include media-breakpoint-up(sm) {
    .input {
        &--expanded {
            width: search-width(smallest);
        }
    }
}

@include media-breakpoint-up(md) {
    .input {
        &--expanded {
            width: search-width(small);
        }
    }
}

@include media-breakpoint-up(lg) {
    .input {
        &--expanded {
            width: search-width(base);
        }
    }
}
