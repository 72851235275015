@import 'src/scss/tokens';

.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;

    &:focus {
        outline: none;
    }

    &.no-scrollbar {
        scrollbar-width: none; // Firefox
        -ms-overflow-style: none; // IE 10+

        &::-webkit-scrollbar { // Chrome
            display: none;
        }
    }
}
