@import 'src/scss/tokens';

.container {
    background: color(gray, white);
    border-radius: border-radius(rounder);
    box-shadow: shadow(deep);
    padding: spacing(large);
}

.content p + p {
    margin-top: spacing(small);
}

.title {
    font-size: font-size(largest);
    font-weight: font-weight(bolder);
}

.wrapper {
    margin: auto 0;
    padding: spacing(large) 0;
    width: 100%;
}
