@import 'src/scss/tokens';

.dropdown {
    padding-top: spacing(small);
    padding-bottom: spacing(small);
}

.matched-item {
    padding: spacing(base);
    border: border-width(base) solid color(gray, lightest);
    border-radius: border-radius(rounder);
    background: color(gray, white);
}

.matched-item-listing {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(small);
}

.content-image {
    max-width: 200px;
    border-radius: border-radius(rounder);
    background-size: cover;
    background-position: center;

    & > a > div {
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
    }
}

.content-title {
    font-size: font-size(larger);
    font-weight: font-weight(bolder);

    a {
        color: color(gray, darkest);
        border-bottom: border-width(base) solid color(gray, white);
        transition: color, border-color;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;

        &:hover {
            border-color: color(gray, darkest);
        }
    }
}

.channel-link {
    @extend .content-title;
    font-size: font-size(large);
}

.notices {
    position: sticky;
    z-index: 1;
    top: spacing(large);
    margin: 0 0 spacing(base);
}

.matched-pages-section {
    min-height: calc(100vh - 60px);
    background-color: color(gray, background);
}

.channel-thumbnail {
    border-radius: 50%;
    height: 44px;
    width: 44px;
}
