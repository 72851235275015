@import 'src/scss/tokens';

.logo-image {
    height: 35px;
}

.hint-dropdown {
    max-width: 234px;
    margin: spacing(small) spacing(base);

    p {
        margin-bottom: spacing(small);
    }
}

.dropdown {
    padding-top: spacing(small);
    padding-bottom: spacing(small);
}

.type-icon {
    width: 18px;
    height: 18px;
}

.treemap-chart-container {
    position: relative;
    height: 354px;
    overflow: hidden;
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
}

.download-banner {
    max-width: 100%;
}

.platform-logo {
    max-width: 70%;
}
