@import 'src/scss/tokens';

.indicator-bar {
    &--theme-gradient {
        .segment {
            height: 0;
            padding: spacing(small);
            background-color: color(gray, lighter);
        }

        &[data-level='low'] {
            .label {
                color: color(red, base);
            }

            .segment.active {
                border-color: color(red, base);
                background-color: color(red, base);
            }
        }

        &[data-level='medium'] {
            .label {
                color: color(yellow, dark);
            }

            .segment.active {
                border-color: color(yellow, base);
                background-color: color(yellow, base);
            }
        }

        &[data-level='high'] {
            .label {
                color: color(aqua, darker);
            }

            .segment.active {
                border-color: color(aqua, darker);
                background-color: color(aqua, darker);
            }
        }

        &[data-level='unknown'] {
            .label {
                color: color(gray, dark);
            }
        }

        &[data-level='loading'] {
            .label {
                color: color(gray, dark);
            }

            .segment {
                background-color: color(gray, white);
            }
        }

        .label {
            font-weight: font-weight(bold);
            font-size: font-size(large);
        }

        .spinner-wrapper {
            font-size: font-size(larger);
            color: color(gray, dark);
        }
    }

    &--theme-flat {
        display: flex;
        flex-direction: column-reverse;

        .segment {
            height: 32px;
            padding: spacing(base);
            border-color: color(gray, white);
            font-size: font-size(larger);
            background-color: transparent;

            &.active {
                border-color: color(purple, base);
                background-color: color(purple, base);
            }

            &.negative {
                padding-right: spacing(small);
                padding-left: spacing(small);
                border-color: color(gray, white);
                color: color(gray, white);
                background-color: transparent;

                & > svg {
                    width: 16px;
                }
            }

            &.positive {
                padding-right: spacing(small);
                padding-left: spacing(small);
                border-color: color(gray, white);
                color: color(purple, base);
                background-color: color(gray, white);

                & > svg {
                    width: 16px;
                }
            }
        }

        &[data-level='low'] {
            .label {
                color: color(red, base);
            }
        }

        &[data-level='medium'] {
            .label {
                color: color(yellow, dark);
            }
        }

        &[data-level='high'] {
            .label {
                color: color(aqua, darker);
            }
        }

        &[data-level='unknown'] {
            .label {
                color: color(gray, white);
            }
        }

        &[data-level='loading'] {
            .label {
                color: color(gray, white);
            }

            .segment {
                border-color: color(purple, light);
                background-color: color(purple, darker);
            }
        }

        .label {
            margin-top: spacing(small);
            font-size: font-size(large);
        }

        .spinner-wrapper {
            font-size: font-size(huge);
            color: color(gray, white);
        }
    }

    &.clickable {
        cursor: pointer;
    }
}

.segment {
    display: flex;
    justify-content: center;
    align-items: center;
    border: border-width(base) solid color(gray, lighter);
    border-radius: border-radius(small);
}

.spinner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    font-size: font-size(large);

    &--theme-gradient {
        font-weight: font-weight(bold);
        color: color(gray, dark);
    }

    &--theme-flat {
        color: color(gray, white);
    }
}
