@import 'src/scss/tokens';

.container {
    position: relative;
    margin: auto;
    fill: none;
    z-index: 1;
}

.content {
    overflow: visible;
}

.arc {
    > circle {
        stroke: color(gray, lighter);
        stroke-width: border-width(base);
    }

    > path {
        stroke-linecap: round;
        stroke-width: border-width(thicker);
    }
}

.radial-chart-icon-text {
    cursor: default;
    pointer-events: bounding-box;

    > foreignObject {

        > div {
            position: relative;
            height: 100%;
            font-size: font-size(small);
            color: color(gray, darkest);

            > div {
                position: absolute;
                padding: spacing(smaller);
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: 105px;

                > svg {
                    stroke: color(purple, base);
                    fill: color(purple, base);
                }
            }
        }
    }
}
