@import 'src/scss/tokens';

@each $colorKey in map-keys($color) {
    @each $tone in map-keys(map-get($color, $colorKey)) {
        .background--#{$colorKey}-#{$tone} {
            background-color: color($colorKey, $tone);
        }
        .color--#{$colorKey}-#{$tone} {
            color: color($colorKey, $tone);
            fill: color($colorKey, $tone);
            stroke: color($colorKey, $tone);
        }
    }
}

.emblem {
    display: flex;
    justify-content: center;
    align-items: center;

    > .name {
        font-size: font-size(large);
        font-weight: font-weight(bold);
        width: calc(100% - spacing(base));
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
}
