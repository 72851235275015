@import 'src/scss/tokens';

.logo-wrapper {
    display: flex;
    justify-content: center;
    margin: auto;
    img {
        height: 35px;
        max-width: 100%;
    }
}

.description {
    div + div {
        margin-top: spacing(large);
    }

    h3 {
        font-weight: font-weight(bold);
        font-size: font-size(larger);
    }

    ol {
        list-style: decimal;
        padding-left: 20px;
    }

    li {
        margin-top: spacing(small);
        padding-left: spacing(small);
        font-size: font-size(large);
    }
}

.check-button-label {
    display: flex;
    align-items: center;
    margin: 4px 0;
    height: 51px;
}

.custom-button-knowledge-base {
    border: border-width(base) solid color(purple, lighter) !important;
}

.lightbulb-icon-container {
    height: 100%;

    svg {
        display: block;
        margin: auto;
        height: 100%;
    }
}

.video-player {
    border-radius: border-radius(rounder);
}

.tracker-bar {
    padding: spacing(smallest);
    display: flex;
    background-color: color(gray, light);
    margin-top: spacing(small);

    &.left-radius {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &.right-radius {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.time-complete {
        background-color: color(purple, darker);
    }
}

.icon {
    font-size: font-size(largest);
    color: color(gray, light);
}

