@import 'src/scss/tokens';

.toaster {
    margin: 0 0 spacing(base);
    padding: 0 spacing(large);
    position: fixed;
    right: 0;
    bottom: 0;
    max-width: container-width(smaller);
    width: 100%;
    z-index: 9999;
}

.container {
    background: color(purple, darker);
    border-radius: border-radius(rounder);
    box-shadow: shadow(deep);
    overflow: hidden;
    padding: spacing(base);
    position: relative;

    &--theme-danger {
        background: color(red, lightest);

        .title {
            color: color(red, darker);
        }
        .content p {
            color: color(red, darkest);
        }
        .timer-bar {
            background: color(red, base);
        }
        .timer-bar--empty {
            background: color(red, lightest);
        }
    }
}

.title {
    color: color(gray, white);
    font-size: font-size(large);
    font-weight: font-weight(bolder);
    margin: 0 0 spacing(smaller);

    &:last-child {
        margin: 0;
    }
}

.content {
    p {
        color: color(purple, lightest);
        font-size: font-size(base);
        line-height: line-height(large);
        margin: 0 0 spacing(smaller);

        &:last-child {
            margin: 0;
        }
    }
}

.timer-bar {
    background: color(purple, base);
    bottom: 0;
    height: spacing(smaller);
    left: 0;
    position: absolute;
}

.timer-bar--empty {
    background: color(purple, lighter);
    width: 100%;
}
