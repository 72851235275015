@import 'src/scss/mixins';
@import 'src/scss/tokens';

.topbar {
    display: flex;
    flex-direction: column;
    margin-right: -#{spacing(base)};
    margin-left: -#{spacing(base)};
}

.breadcrumbs {
    padding: spacing(small) spacing(base);
    overflow: hidden;
}

.actions {
    display: flex;
    padding: spacing(small) spacing(base);
    border-top: border-width(base) solid color(gray, lightest);
    background-color: color(gray, white);

    > :not(:first-child) {
        width: min-content;
        margin-left: spacing(small);
    }
}

@include media-breakpoint-up(sm) {
    .topbar {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        margin-left: 0;
        padding: spacing(small) 0;
    }

    .breadcrumbs {
        padding-right: 0;
        padding-left: 0;
    }

    .actions {
        margin-left: auto;
        padding: 0;
        border-top: none;
        border-bottom: none;
        background-color: transparent;

        > :first-child {
            margin-left: spacing(large);
        }
    }
}
