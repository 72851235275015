@import 'src/scss/tokens';

.container {
    > p {
        font-size: font-size(base);
        line-height: line-height(large);
        margin-top: spacing(small);
        margin-bottom: spacing(small);
        max-width: 42.5em;

        * {
            line-height: inherit;
        }
    }

    &.base {
        padding: spacing(small) spacing(base);
        border-radius: border-radius(rounder);
    }

    &.small {
        overflow: auto;
        padding: 0 spacing(base);
        border-radius: border-radius(round);
    }

    &.primary {
        background-color: color(purple, lightest);
        color: color(purple, darker);
    }

    &.danger {
        background-color: color(red, lightest);
        color: color(red, darker);
    }

    &.empty {
        border: 2px dashed color(gray, lighter);
        color: color(gray, dark);
    }
}

.action-items {
    display: flex;
    justify-content: flex-end;
    margin: spacing(small) 0;
}
