@import 'src/scss/tokens';

.summary {
    border-radius: border-radius(rounder);
    background-color: rgba(color(gray, white), 0.75);
    backdrop-filter: blur(20px);
}

.matched-item {
    padding: spacing(base);
    border: border-width(base) solid color(gray, lightest);
    border-radius: border-radius(rounder);
    background: color(gray, white);

    .content-image {
        overflow: hidden;
        border-radius: border-radius(rounder);

        img {
            max-width: 200px;
            aspect-ratio: 16 / 9;
        }
    }

    .content-title {
        font-size: font-size(larger);
        font-weight: font-weight(bolder);

        a {
            color: color(gray, darkest);
            border-bottom: border-width(base) solid color(gray, white);
            transition-property: color, border-color;
            transition-duration: transition-duration(base);
            transition-timing-function: $transition-timing-function;

            &:hover {
                color: color(purple, darker);
                border-color: color(purple, base);

                .content-title-icon {
                    color: color(purple, darker);
                }
            }
        }
    }

    .content-title-icon {
        color: color(purple, dark);
        transition: color transition-duration(base) $transition-timing-function;
    }
}
