@import 'src/scss/mixins';
@import 'src/scss/tokens';

$track-width: 124px;

.filters {
    display: inline-block;
    padding-right: spacing(smaller);
    padding-left: spacing(smaller);
    border: border-width(base) solid color(gray, lightest);
    background-color: color(gray, white);
    border-radius: border-radius(round);
}

.filters-title {
    font-weight: font-weight(bold);
    line-height: input-height(small);
}

.filter-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.no-filters {
    padding: 0 spacing(base);
    border-radius: border-radius(rounded);
    color: color(gray, dark);
    line-height: input-height(small);
    background-color: color(gray, background);
}

.filter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: input-height(small);
    margin: 0;
    padding: 0 spacing(base);
    border: border-width(base) solid color(purple, base);
    border-radius: 999px;
    font-weight: font-weight(bold);
    font-size: font-size(small);
    line-height: line-height(base);
    font-family: font-family(base);
    text-align: center;
    color: color(purple, dark);
    background-color: color(gray, white);
    transition-property: background-color, border-color, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
    cursor: pointer;

    &:visited {
        color: color(gray, white);
    }

    &:focus {
        outline: none;
    }

    &:hover {
        border-color: color(purple, dark);
        color: color(gray, white);
        background-color: color(purple, dark);
    }

    .suffix {
        margin-left: spacing(small);
    }
}

.dropdown-option {
    margin-top: spacing(smallest);
    margin-bottom: spacing(smallest);
    padding-right: spacing(small);
    padding-left: spacing(small);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .icon {
        margin-right: spacing(small);
        color: color(gray, darkest);
        transition: color transition-duration(base) $transition-timing-function;
    }

    .content {
        display: flex;
        padding: spacing(small);
        border-radius: border-radius(round);
        font-weight: font-weight(bold);
        color: color(purple, dark);
        cursor: pointer;
        transition: background, color;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;

        &:active {
            color: color(gray, white);
            background: color(purple, darker);
        }

        &:hover {
            color: color(gray, white);
            background: color(purple, dark);

            .icon {
                color: color(gray, white);
            }
        }
    }

    .label {
        width: 100%;
        @include ellipsis();
    }

    &.selected .content {
        color: color(gray, white);
        background: color(purple, darker);
        transition: background transition-duration(base) $transition-timing-function;

        .icon {
            color: color(gray, white);
        }
    }
}

.scroll {
    color: color(purple, dark);
    transition: color transition-duration(base) $transition-timing-function;

    &:hover {
        color: color(purple, darker);
    }
}

.table-wrapper {
    overflow-x: auto;
}

.fixed-width-cell {
    min-width: $track-width;
}

.group-logo {
    width: 28px;
    height: 28px;
    object-fit: contain;
}
