@import 'src/scss/tokens';

.container {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    em {
        line-height: inherit;
    }
}

@each $colorKey in map-keys($color) {
    @each $tone in map-keys(map-get($color, $colorKey)) {
        .color--#{$colorKey}-#{$tone} {
            color: color($colorKey, $tone);
        }
    }
}

@each $key in map-keys($line-height) {
    .line-height--#{$key} {
        line-height: line-height($key);
    }
}

@each $key in map-keys($spacing) {
    .spacing--#{$key} {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin-bottom: spacing($key);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@each $key in map-keys($font-size) {
    .size--#{$key} {
        font-size: font-size($key);
    }
}

@each $key in map-keys($font-weight) {
    .weight--#{$key} {
        font-weight: font-weight($key);
    }
}

$text-trasform-options: uppercase, lowercase, capitalize;
@each $key in $text-trasform-options {
    .text-transform--#{$key} {
        text-transform: $key;
    }
}

.width--readable {
    max-width: 44em;
}

.aligned--center {
    text-align: center;
}

.aligned--right {
    text-align: right;
}
