@import 'src/scss/tokens';
@import 'src/scss/mixins';

.label {
    color: color(gray, dark);
    font-size: font-size(base);
}

.description {
    font-size: font-size(large);
    margin-bottom: spacing(base);
}

.highlight {
    font-weight: font-weight(bold);
}

.buttons {
    display: inline-flex;
    gap: spacing(small);
}

.expander {
    display: flex;
    align-items: center;
    gap: spacing(small);
    font-size: font-size(small);
    color: color(gray, dark);
}

.rule-separator {
    margin-left: spacing(base);
    padding: spacing(base);
    border-left: 2px solid color(gray, lighter);
    font-weight: font-weight(bold);
    font-size: font-size(large);
}

.keyword-rule {
    padding: spacing(small) spacing(base) spacing(base);
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
}

@include media-breakpoint-up(sm) {
    .label,
    .description,
    .buttons {
        margin-top: spacing(small);
        margin-bottom: spacing(small);
    }

    .label {
        width: 100px;
        color: color(gray, darkest);
        font-size: font-size(large);
        text-align: right;
    }

    .description {
        margin-left: spacing(base);
        padding-right: spacing(base);
        padding-left: spacing(base);
        border-left: border-width(base) solid color(gray, lighter);
    }

    .buttons {
        flex-direction: column;
    }
}

@include media-breakpoint-up(md) {
    .buttons {
        flex-direction: row;
    }
}
