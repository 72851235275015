@use 'sass:math';
@import 'src/scss/tokens';

.recommendation {
    position: relative;
    padding-left: spacing(large) + spacing(base);

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: spacing(large);
        height: border-width(base);
        margin-left: spacing(base);
        background-color: color(gray, lightest);
    }

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translate(-50%, -50%);
        left: spacing(base) + math.div(border-width(thick), 2);
        width: 22px;
        height: 22px;
        background: url(../images/rocket-inactive.svg) no-repeat left center / cover;
    }

    .date-badge {
        padding: spacing(smaller) spacing(small);
        border: border-width(base) solid color(gray, base);
        border-radius: border-radius(round);
        font-weight: font-weight(bold);
        font-size: font-size(small);
        color: color(gray, base);
        background-color: color(gray, lightest);
    }

    &.active {
        &::after {
            background-image: url(../images/rocket.svg);
        }

        .date-badge {
            border-color: color(blue, light);
            color: color(purple, darker);
            background-color: color(blue, lightest);
        }
    }
}
