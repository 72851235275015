@import 'src/scss/tokens';

.hint-dropdown {
    max-width: 250px;
    padding: spacing(small) spacing(base);

    p + p {
        margin-top: spacing(small);
    }
}

// This mimics a large disabled button to solve the problem of blocking events
// (onMouseEnter in this case) on a disabled button
.fake-button {
    border: 1px solid color(purple, base);
    background: color(purple, base);
    border-radius: border-radius(round);
    color: color(gray, white);
    cursor: default;
    font-weight: bold;
    line-height: 1em;
    padding: 0 spacing(base);
    text-align: center;
    vertical-align: top;
    height: input-height(large);
    font-size: font-size(large);
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0.7;
    filter: saturate(0);
}
