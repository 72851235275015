@import 'src/scss/mixins';
@import 'src/scss/tokens';

.toggle {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0;
    padding: 0 spacing(base);
    border: 1px solid color(gray, light);
    border-radius: border-radius(round);
    font-weight: font-weight(bold);
    line-height: line-height(base);
    font-family: font-family(base);
    text-align: left;
    color: color(gray, darkest);
    background: color(gray, white);
    outline: none;
    transition-property: border-color, background;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &--size-base {
        min-height: input-height(base);
        font-size: font-size(base);
    }

    &--size-large {
        min-height: input-height(large);
        font-size: font-size(large);
    }

    &--has-content {
        cursor: pointer;

        &:hover {
            background: color(gray, background);
        }

        &.toggle:active,
        &.toggle--active {
            border-color: color(gray, base);
            font-weight: font-weight(bolder);
            background: color(gray, background);
        }
    }

    &--fluid-height {
        padding-top: spacing(small);
        padding-bottom: spacing(small);
    }

    &--disabled {
        pointer-events: none;
    }
}

.inner {
    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    line-height: inherit;
}

.inner-top {
    display: flex;
    align-items: center;
    width: 100%;
}

.content {
    width: 100%;
    flex: 1;
    @include ellipsis();
}

.prefix,
.suffix {
    flex-shrink: 0;
}

.prefix {
    $image-size: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: $image-size;
    height: $image-size;
    overflow: hidden;
    margin-right: spacing(base);
    border-radius: border-radius(round);

    & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.icon {
    $icon-size: 21px;

    width: $icon-size;
    height: $icon-size;
    line-height: $icon-size;
    margin-left: spacing(base);
    text-align: right;
    color: color(gray, darker);
}

.collapse {
    border-radius: border-radius(base);
    background-color: color(gray, white);
}

.spinner {
    font-size: font-size(base);
}
