@import 'src/scss/tokens';

.edit {
    vertical-align: baseline;
    font-size: font-size(small);
    font-weight: font-weight(base);
    color: color(purple, dark);
    transition: color transition-duration(base) $transition-timing-function;

    &:hover {
        color: color(purple, darker);
    }
}

.long-name-wrapper {
    word-wrap: break-word;
}
