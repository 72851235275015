@import 'src/scss/tokens';

.container {
    display: inline-block;
}

.month-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9px;
    height: input-height(base);
    margin: 0;
    padding: 0;
    border: none;
    font-size: font-size(base);
    color: color(purple, dark);
    background: none;
    transition-property: color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
    cursor: pointer;

    &:focus,
    &:hover {
        color: color(purple, darker);
        outline: none;
    }

    &[disabled] {
        opacity: 1;
        color: color(gray, light);
    }
}

.calendar {
    th,
    td {
        min-width: input-height(base);
        width: input-height(base);
        height: input-height(base);
        text-align: center;
        vertical-align: middle;
    }

    th {
        font-weight: font-weight(bold);
    }

    td {
        color: color(gray, dark);
        cursor: pointer;
        transition: border-radius, background-color, color;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;

        &.in-range {
            background-color: color(purple, lightest);
            color: color(purple, darker);
        }

        &.end-of-range {
            font-weight: font-weight(bold);
            color: color(purple, darker);
            background-color: color(purple, lighter);
        }

        &.selection {
            border-radius: border-radius(round);
            font-weight: font-weight(bold);
            color: color(gray, white);
            background-color: color(purple, darker);
            pointer-events: none;
        }

        &.range-start,
        &.selection-start {
            border-radius: border-radius(round) 0 0 border-radius(round);
        }

        &.range-end,
        &.selection-end {
            border-radius: 0 border-radius(round) border-radius(round) 0;
        }

        &.disabled {
            color: color(gray, dark);
            pointer-events: none;
        }

        &.muted:not(.end-of-range):not(.in-range) {
            color: color(gray, lighter);
        }

        &.hidden {
            visibility: hidden;
        }

        &:active,
        &:hover {
            font-weight: font-weight(bold);
            color: color(gray, white);
            background-color: color(purple, dark);
        }
    }
}

.selected-container {
    padding-top: spacing(small);
    border-top: border-width(base) solid color(gray, lightest);
    text-align: center;
}

.date-range-container {
    display: flex;

    &.has-error {
        .start-date-container,
        .end-date-container {
            border-color: color(red, dark);
        }
    }
}

.date-container {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: spacing(base);
}

.start-date-container,
.end-date-container {
    border: border-width(base) solid color(gray, lightest);
    transition: border-color transition-duration(base) $transition-timing-function;
}

.start-date-container {
    align-items: flex-start;
    border-radius: border-radius(round) 0 0 border-radius(round);
}

.end-date-container {
    align-items: flex-end;
    border-left: none;
    border-radius: 0 border-radius(round) border-radius(round) 0;
}
