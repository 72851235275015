.tasks-flexbox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 55px);
}

.status-section {
    display: flex;
    flex: 1;
    align-items: center;
    align-self: center;
    text-align: center;
}

.override-ol {
    list-style: decimal;
    margin-left: 30px;
}
