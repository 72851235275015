@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
}

.crumbs {
    display: flex;
    align-items: center;
}

.crumb {
    font-weight: font-weight(bold);
    font-size: font-size(large);
    color: color(gray, darkest);
    cursor: default;

    &:last-child {
        font-weight: font-weight(bolder);
    }

    &--inactive {
        font-weight: font-weight(base);
        color: color(gray, dark);
    }
}

.link,
.link:visited {
    color: inherit;

    &:hover {
        text-decoration-line: underline;
        color: inherit;
    }
}

.separator {
    margin-right: spacing(smaller);
    margin-left: spacing(smaller);
    color: color(gray, light);
}
