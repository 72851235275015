@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    align-items: center;
    background: color(gray, white);
    border: 1px solid color(gray, light);
    border-radius: border-radius(rounder);
    display: flex;
    height: 100%;
    justify-content: space-between;
    min-height: 175px;
    padding: spacing(base);
    transition: border-color, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &:focus {
        border-color: color(gray, dark);
        outline: none;
    }

    &:hover {
        border-color: color(gray, dark);
        text-decoration: none;

        .icon {
            color: color(gray, dark);
        }
    }

    &--preview {
        pointer-events: none;
        max-width: 400px;

        .title {
            margin-bottom: auto;
            margin-top: spacing(small);
            padding-top: spacing(smaller);
        }
    }

    &--card-generic {
        align-items: flex-start;

        .content-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .icon {
            align-self: center;
        }

        &.container-slim {
            padding: spacing(base);

            .title {
                font-size: font-size(large);
                font-weight: font-weight(bold);
            }
        }
    }

    &--card-loading {
        justify-content: center;

        &:hover {
            border-color: color(gray, light);
        }
    }

    &--card-link {
        flex-direction: column;
        justify-content: center;

        .title {
            margin: 0;
            font-size: font-size(large);
            font-weight: font-weight(bold);
            text-align: center;
        }

        .icon {
            padding: 0;
            margin: 0 0 spacing(small);
            display: flex;
            justify-content: center;
            width: 35px;
            height: 35px;
            background-color: color(purple, base);
            border-radius: border-radius(round);
            align-items: center;
            transition: background-color transition-duration(base)
                $transition-timing-function;
        }

        .icon p {
            color: color(gray, white);
            font-size: font-size(largest);
            justify-content: center;
        }

        &:hover .icon {
            background-color: color(purple, dark);
        }
    }

    &--card-full,
    &--card-user {
        padding: 0;
        flex-direction: column;
        align-items: flex-start;

        .content {
            padding: spacing(base);
            flex: 1;
            justify-content: space-between;
        }
    }

    &--card-user {
        .topline {
            margin-bottom: spacing(small);
        }

        .content {
            justify-content: flex-start;
        }
    }

    &.shadow {
        border: 1px solid color(gray, light);
        box-shadow: shadow(shallow);
        transition: box-shadow transition-duration(base)
            $transition-timing-function;

        &:hover {
            box-shadow: shadow(deep);
        }
    }
}

.content {
    position: relative;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    max-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logo-preview {
    border: 1px dashed color(gray, lighter);
    color: color(gray, light);
    width: 150px;
    text-align: center;
    padding: 3px;
}

.topline {
    margin-right: 36px;
    color: color(gray, darkest);
    font-size: font-size(base);
    font-weight: font-weight(bold);
    @include ellipsis();
}

.favorites {
    position: absolute;
    top: spacing(base);
    right: spacing(base);
}

.title {
    color: color(gray, darkest);
    font-size: font-size(larger);
    font-weight: font-weight(bolder);
    margin-right: 36px;
    @include ellipsis();
}

.description {
    color: color(gray, dark);
    font-size: font-size(base);
    line-height: line-height(large);
    margin-top: spacing(small);

    > p + p {
        margin-top: spacing(small);
    }
}

.date {
    color: color(gray, darkest);
    font-size: font-size(small);
}

.icon {
    color: color(gray, light);
    font-size: font-size(large);
    font-weight: font-weight(bold);
    line-height: line-height(base);
    padding: 0 0 0 spacing(base);
    transition: color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}

.pill-content {
    display: inline-flex;
    align-items: center;
    gap: spacing(small);
}

.badge {
    width: 100%;
    padding: spacing(base);
    border-top: 1px solid color(gray, lightest);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    > div:nth-of-type(n + 2) {
        margin-left: spacing(smaller);
    }

    &::after {
        content: '';
        display: block;
        background: linear-gradient(
            270deg,
            white,
            50%,
            rgba(color(gray, white), 0)
        );
        border-bottom-right-radius: border-radius(rounder);
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: 30px;
        z-index: 1;
    }
}

.quick-actions {
    align-items: center;
    background: linear-gradient(
        270deg,
        white,
        97%,
        rgba(color(gray, white), 0)
    );
    border-bottom-right-radius: border-radius(rounder);
    box-sizing: content-box;
    bottom: 0;
    display: flex;
    height: 28px;
    right: 0;
    padding: spacing(base);
    position: absolute;
    z-index: 2;
}

.deal-logo {
    display: block;
}

.deal-scroll {
    max-height: 250px;
    overflow-y: scroll;
}

.container-slim {
    background-color: color(gray, white);
    border: 1px solid color(gray, light);
    border-radius: border-radius(round);
    padding: spacing(small);
    transition: border-color transition-duration(base)
        $transition-timing-function;

    &:hover {
        border: 1px solid color(gray, dark);
    }
}

.card-slim-container {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.card-slim-content {
    display: flex;
    position: relative;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30px;
        background: linear-gradient(
            270deg,
            white,
            50%,
            rgba(color(gray, white), 0)
        );
    }
}

.account-logo {
    width: 35px;
    height: 35px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.account-no-logo {
    width: 35px;
    height: 35px;
    border-radius: border-radius(round);
    border: border-width(base) dashed color(gray, lighter);
}

.interactive-scale-tooltip {
    padding: spacing(base);
}

.tile-scale {
    width: 3px;
    height: 3px;

    &[data-level='low'] {
        background-color: color(red, light);
    }

    &[data-level='medium'] {
        background-color: color(yellow, light);
    }

    &[data-level='high'] {
        background-color: color(aqua, dark);
    }

    &[data-level='unknown'] {
        background-color: color(gray, light);
    }
    &[data-level='active'] {
        background-color: color(gray, white);
    }
}
.tile-scale:not(:last-child) {
    margin-right: 1px;
}

.indicator-value {
    text-align: center;
}