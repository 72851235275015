@import 'src/scss/tokens';

.container {
    display: flex;
    justify-content: center;
}

.wrapper {
    margin: auto 0;
    width: 100%;
}

.absolute {
    align-items: center;
    background: rgba(color(gray, white), 0.85);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.transparent {
    background: transparent;
}
