@import 'src/scss/tokens';

.icon {
    color: color(purple, dark);
    cursor: pointer;

    &.active {
        color: color(purple, darker);
    }
}
