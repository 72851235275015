@import 'src/scss/tokens';

.container {
    margin: 0 auto;
    position: relative;
    cursor: pointer;

    video {
        width: 100%;
        background-color: color(gray, darkest);
    }

    .header {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.75);
        color: white;
        font-weight: font-weight(bold);
        padding: spacing(small);
    }

    .tagsContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: spacing(base);
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        user-select: none;
        pointer-events: none;

        &.hasHeader {
            top: spacing(large);
        }

        &.left {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
        }
        &.bottom {
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            align-content: flex-end;

            > .tag {
                z-index: 1000;
                background-color: white;
                color: black;
            }
        }

        > .tag {
            z-index: 1000;
            background-color: black;
            color: white;
            font-size: font-size(base);
            font-weight: font-weight(bold);
            font-family: font-family(base);
            line-height: line-height(base);
            padding: spacing(smaller) spacing(base);
            margin: spacing(smallest) spacing(small);
            border-radius: border-radius(rounder);
        }
    }

    .buttonContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            color: color(purple, base);
            background-color: transparent;
            border: none;
            cursor: pointer;
            width: 73px;
            height: 73px;
            padding: 0;
            opacity: 0.75;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover {
                color: color(purple, dark);
            }
        }

        &:hover {
            button {
                opacity: 1;
            }
        }
    }
}

.video-player {
    border-radius: border-radius(rounder);
}

.image {
    width: 35px;
    height: 35px;
}

.image-logos-tooltip {
    width: 100%;
}

.clickable-area {
    width: 100%;
    line-height: 2.5em;
    text-align: right;
}
