// The reason why we prefer maps and getter functions is so that we can iterate
// the values easily. As a very basic example, we can easily produce a class for
// each font-size:
// @each $size in map-keys($font-size) {
//     .font-size-$size {
//         font-size: font-size($size);
//     }
// }
@use "sass:string";

$breakpoint: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

@function breakpoint($key) {
    @return map-get($breakpoint, $key);
}

$border-radius: (
    none: 0px,
    small: 2px,
    base: 4px,
    round: 8px,
    rounder: 12px,
    rounded: 999px,
);

@function border-radius($radius) {
    @return map-get($border-radius, $radius);
}

$border-width: (
    base: 1px,
    thick: 2px,
    thicker: 4px,
);

@function border-width($key) {
    @return map-get($border-width, $key);
}

$input-height: (
    smaller: 18px,
    small: 28px,
    base: 35px,
    large: 42px,
);

@function input-height($height) {
    @return map-get($input-height, $height);
}

$color: (
    "purple": (
        "darkest": #0d134a,
        "darker": #242a6b,
        "dark": #3040e8,
        "base": #6673ff,
        "light": #99a2ff,
        "lighter": #ccd0ff,
        "lightest": #e5e8ff,
    ),
    "red": (
        "darkest": #450810,
        "darker": #730d1a,
        "dark": #ce172f,
        "base": #ea465b,
        "light": #ef7685,
        "lighter": #f5a3ae,
        "lightest": #fad1d6,
    ),
    "yellow": (
        "darkest": #4d3b00,
        "darker": #806200,
        "dark": #bf9300,
        "base": #ffc400,
        "light": #ffe380,
        "lighter": #fff0b3,
        "lightest": #fffae6,
    ),
    "aqua": (
        "darkest": #093f43,
        "darker": #137f86,
        "dark": #1cbec9,
        "base": #4cdce6,
        "light": #79e5ec,
        "lighter": #a6eef3,
        "lightest": #d2f6f9,
    ),
    "gray": (
        "black": #000000,
        "darkest": #1a1a1a,
        "darker": #404040,
        "dark": #585858,
        "base": #808080,
        "light": #b3b3b3,
        "lighter": #d9d9d9,
        "lightest": #e8e8e8,
        "background": #f4f4f4,
        "white": #ffffff,
    ),
    "blue": (
        "darkest": #0747a6,
        "darker": #0052cc,
        "dark": #0065ff,
        "base": #2684ff,
        "light": #4c9aff,
        "lighter": #b3d4ff,
        "lightest": #deebff,
    ),
);

@function color($type, $tone) {
    @return map-get(map-get($color, "" + $type), "" + $tone);
}

$container-width: (
    smaller: 440px,
    small: 720px,
    medium: 1000px,
    base: 1264px,
);

@function container-width($key) {
    @return map-get($container-width, $key);
}

// "SF Pro Text" and "SF Pro Display" are manually specified to work around this bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1057654&q=component%3ABlink%3EFonts%20system-ui%20macos&can=2
$font-family: (
    base: (
        'SF Pro Text',
        'SF Pro Display',
        -apple-system,
        system-ui,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
    ),
    monospace: (
        SFMono-Regular,
        Menlo,
        Monaco,
        Consolas,
        'Liberation Mono',
        'Courier New',
        monospace,
    ),
);

@function font-family($style) {
    @return map-get($font-family, $style);
}

$font-size: (
    smallest: 8px,
    smaller: 10px,
    small: 12px,
    base: 14px,
    large: 16px,
    larger: 18px,
    largest: 21px,
    huge: 30px,
);

@function font-size($size) {
    @return map-get($font-size, $size);
}

$font-weight: (
    base: 400,
    bold: 600,
    bolder: 800,
);

@function font-weight($weight) {
    @return map-get($font-weight, $weight);
}

$line-height: (
    base: 1.35em,
    large: 1.5em,
);

@function line-height($key) {
    @return map-get($line-height, $key);
}

$shadow: (
    shallow: 0 0 5px 0 rgba(color(gray, black), 0.125),
    base: 0 1px 8px 0 rgba(color(gray, black), 0.15),
    deep: 0 3px 15px 0 rgba(color(gray, black), 0.2),
);

@function shadow($key) {
    @return map-get($shadow, $key);
}

$spacing: (
    none: 0,
    smallest: 2px,
    smaller: 4px,
    small: 8px,
    base: 16px,
    large: 32px,
    larger: 64px,
);

@function spacing($size) {
    @return map-get($spacing, $size);
}

// This function is used to calculate the amount of padding
// required at the bottom of containers to ensure even spacing depending
// on the spacing on of the content the container contains.
@function spacing-remainder($base, $size) {
    @return (map-get($spacing, $base) - map-get($spacing, $size));
}

$transition-duration: (
    fast: 0.1s,
    base: 0.2s,
    slow: 0.3s,
    slower: 0.5s,
);

@function transition-duration($speed) {
    @return map-get($transition-duration, $speed);
}

$transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$search-width: (
    smallest: 300px,
    small: 500px,
    base: 600px,
);

@function search-width($key) {
    @return map-get($search-width, $key);
}
