@import 'src/scss/mixins';
@import 'src/scss/tokens';

.section {
    @include clearfix;

    &--read-only {
        padding-top: spacing(smaller);
        padding-bottom: spacing(smaller);
    }
}

.options {
    display: flex;
    flex-direction: column;
}

.option {
    width: 100%;

    .option-handler {
        display: flex;
        align-items: center;
        padding: spacing(base);
        position: relative;
        border-radius: border-radius(round);
        border: 1px solid color(gray, light);
        font-weight: font-weight(bold);
        background-color: color(gray, white);
        cursor: pointer;
        transition: background-color, box-shadow, color, border-color;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;
        user-select: none;

        &:hover {
            border-color: color(purple, dark);
            color: color(purple, dark);

            .icon {
                border-color: color(purple, dark);
                color: color(purple, dark);
            }
        }
    }

    .icon-container {
        width: 35px;
        height: 35px;
    }

    .icon {
        max-width: 100%;
        max-height: 100%;
        border-color: color(gray, dark);
        color: color(gray, dark);
        transition: stroke, fill;
        transition-duration: transition-duration(base);
        transition-timing-function: $transition-timing-function;
    }

    .label-content {
        flex-grow: 1;
        padding: 0 spacing(small);
    }

    .hint-container {
        pointer-events: auto;
    }

    input[type='radio'] {
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute;
        white-space: nowrap;
        opacity: 0;
        border-width: 0;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        overflow: hidden;
        padding: 0;

        &:checked + .option-handler {
            border-color: color(purple, darker);
            background-color: color(gray, white);
            pointer-events: none;

            .icon {
                border-color: color(purple, darker);
                color: color(purple, darker);
            }
        }

        &:focus + .option-handler {
            border-color: color(purple, darker);
            box-shadow: 0 0 0 spacing(smaller) rgba(color(purple, lighter), 0.75);
            outline: none;

            .icon {
                border-color: color(purple, darker);
                color: color(purple, darker);
            }
        }
    }

    & + & {
        margin-top: spacing(small);
    }
}

@include media-breakpoint-up(sm) {
    .options {
        flex-direction: row;
    }

    .option {
        & + & {
            margin-top: 0;
            margin-left: spacing(base);
        }
    }
}
