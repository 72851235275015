@import 'src/scss/tokens';
@import 'src/scss/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
}

.sidebar {
    flex: 0 0;
    z-index: 2;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    flex: 1;
}

.content {
    flex: 1 1 0;
    width: 100%;

    &--align-center {
        align-items: center;
        display: flex;
    }

    &--align-fixed {
        height: 100%;
        overflow: auto;
    }
}

.header,
.footer {
    flex-shrink: 1;
    width: 100%;
}

.footer {
    &--sticky {
        position: sticky;
        z-index: 2;
        bottom: 0;
    }
}

@include media-breakpoint-up(md) {
    .wrapper {
        max-height: 100vh;
        flex-direction: row;
    }
}
