@import 'src/scss/mixins';
@import 'src/scss/tokens';

.layer-pill {
    display: inline-block;
}

.wrapper {
    display: inline-flex;
    max-width: 100%;
    overflow: hidden;

    button {
        margin: 0;
        border: none;
        height: 100%;
    }
}

.container {
    border-radius: 9999px;
    background: color(purple, base);
    border: 1px solid color(purple, base);
    color: color(gray, white);
    display: inline-block;
    font-weight: font-weight(bold);
    transition: background, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
    @include ellipsis();

    &--has-prefix-button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    &--has-suffix-button {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}

.dropdown-container {
    width: 300px;
}

button.prefix-button,
button.suffix-button {
    background: color(purple, base);
    border: 1px solid color(purple, base);
    color: color(gray, white);
    cursor: pointer;
    transition: background, border-color, box-shadow, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &:focus {
        background: color(purple, dark);
        border-color: color(purple, dark);
        box-shadow: 0 0 0 spacing(smallest) color(purple, light) inset;
        outline: none;
    }

    &.active,
    &:hover {
        background: color(purple, darker);
        border-color: color(purple, darker);
        box-shadow: none;
    }
}

button.prefix-button {
    border-right: 1px solid color(purple, dark);
    border-bottom-left-radius: 9999px;
    border-top-left-radius: 9999px;
}

button.suffix-button {
    border-left: 1px solid color(purple, dark);
    border-bottom-right-radius: 9999px;
    border-top-right-radius: 9999px;
}

.size--small {
    font-size: font-size(small);

    .container {
        padding: spacing(smaller) spacing(small);

        &--has-prefix-button {
            padding-left: spacing(smaller);
        }

        &--has-suffix-button {
            padding-right: spacing(smaller);
        }
    }

    button.prefix-button,
    button.suffix-button {
        font-size: font-size(smaller);
        padding: 0 spacing(small);
    }
}

.size--base {
    font-size: font-size(base);

    .container {
        padding: spacing(smaller) spacing(base);

        &--has-prefix-button {
            padding-left: spacing(small);
        }

        &--has-suffix-button {
            padding-right: spacing(small);
        }
    }

    button.prefix-button,
    button.suffix-button {
        font-size: font-size(small);
        padding: 0 spacing(small);
    }
}

.size--large {
    font-size: font-size(larger);

    .container {
        padding: spacing(small) spacing(base);

        &--has-prefix-button {
            padding-left: spacing(base);
        }

        &--has-suffix-button {
            padding-right: spacing(base);
        }
    }

    button.prefix-button,
    button.suffix-button {
        font-size: font-size(large);
        padding: 0 spacing(base);
    }
}

.light {
    .container {
        background: color(purple, lightest);
        border-color: color(purple, lightest);
        color: color(purple, darker);
    }

    .prefix-button,
    .suffix-button {
        background: color(purple, lightest);
        border-color: color(purple, lightest);
        color: color(purple, darker);

        &:focus {
            background: color(purple, lighter);
            border-color: color(purple, dark);
            box-shadow: 0 0 0 spacing(smallest) color(purple, light) inset;
        }

        &.active,
        &:hover {
            background: color(purple, darker);
            border-color: color(purple, darker);
            box-shadow: none;
            color: color(gray, white);
        }
    }

    .prefix-button {
        border-right-color: color(purple, lighter);
    }

    .suffix-button {
        border-left-color: color(purple, lighter);
    }
}

.outline {
    .container {
        background: color(gray, white);
        border-color: color(purple, lighter);
        color: color(purple, dark);

        &--has-prefix-button {
            border-left: none;
        }

        &--has-suffix-button {
            border-right: none;
        }
    }

    .prefix-button,
    .suffix-button {
        background: color(gray, white);
        border-color: color(purple, lighter);
        color: color(purple, dark);

        &:focus {
            background: color(purple, lightest);
            border-color: color(purple, dark);
            box-shadow: 0 0 0 spacing(smallest) color(purple, light) inset;
        }

        &.active,
        &:hover {
            background: color(purple, darker);
            border-color: color(purple, darker);
            box-shadow: none;
            color: color(gray, white);
        }
    }
}

.theme--danger {
    .container {
        background: color(red, base);
        border-color: color(red, base);
    }

    .prefix-button,
    .suffix-button {
        background: color(red, base);
        border-color: color(red, base);

        &:focus {
            background: color(red, dark);
            border-color: color(red, dark);
            box-shadow: 0 0 0 spacing(smallest) color(red, light) inset;
        }

        &.active,
        &:hover {
            background: color(red, darker);
            border-color: color(red, darker);
            box-shadow: none;
        }
    }

    .prefix-button {
        border-right-color: color(red, dark);
    }

    .suffix-button {
        border-left-color: color(red, dark);
    }

    &.light {
        .container {
            background: color(red, lightest);
            border-color: color(red, lightest);
            color: color(red, darker);
        }

        .prefix-button,
        .suffix-button {
            background: color(red, lightest);
            border-color: color(red, lightest);
            color: color(red, darker);

            &:focus {
                background: color(red, lighter);
                border-color: color(red, dark);
                box-shadow: 0 0 0 spacing(smallest) color(red, light) inset;
            }

            &.active,
            &:hover {
                background: color(red, darker);
                border-color: color(red, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }

        .prefix-button {
            border-right-color: color(red, lighter);
        }

        .suffix-button {
            border-left-color: color(red, lighter);
        }
    }

    &.outline {
        .container {
            background: color(gray, white);
            border-color: color(red, lighter);
            color: color(red, dark);

            &--has-prefix-button {
                border-left: none;
            }

            &--has-suffix-button {
                border-right: none;
            }
        }

        .prefix-button,
        .suffix-button {
            background: color(gray, white);
            border-color: color(red, lighter);
            color: color(red, dark);

            &:focus {
                background: color(red, lightest);
                border-color: color(red, dark);
                box-shadow: 0 0 0 spacing(smallest) color(red, light) inset;
            }

            &.active,
            &:hover {
                background: color(red, darker);
                border-color: color(red, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }
    }
}

.theme--muted {
    .container {
        background: color(gray, darker);
        border-color: color(gray, darker);
    }

    .prefix-button,
    .suffix-button {
        background: color(gray, darker);
        border-color: color(gray, darker);

        &:focus {
            background: color(gray, dark);
            border-color: color(gray, dark);
            box-shadow: 0 0 0 spacing(smallest) color(gray, light) inset;
        }

        &.active,
        &:hover {
            background: color(gray, darkest);
            border-color: color(gray, darkest);
            box-shadow: none;
        }
    }

    .prefix-button {
        border-right-color: color(gray, darkest);
    }

    .suffix-button {
        border-left-color: color(gray, darkest);
    }

    &.light {
        .container {
            background: color(gray, lightest);
            border-color: color(gray, lightest);
            color: color(gray, darker);
        }

        .prefix-button,
        .suffix-button {
            background: color(gray, lightest);
            border-color: color(gray, lightest);
            color: color(gray, darker);

            &:focus {
                background: color(gray, lighter);
                border-color: color(gray, dark);
                box-shadow: 0 0 0 spacing(smallest) color(gray, light) inset;
            }

            &.active,
            &:hover {
                background: color(gray, darker);
                border-color: color(gray, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }

        .prefix-button {
            border-right-color: color(gray, light);
        }

        .suffix-button {
            border-left-color: color(gray, light);
        }
    }

    &.outline {
        .container {
            background: color(gray, white);
            border-color: color(gray, lighter);
            color: color(gray, dark);

            &--has-prefix-button {
                border-left: none;
            }

            &--has-suffix-button {
                border-right: none;
            }
        }

        .prefix-button,
        .suffix-button {
            background: color(gray, white);
            border-color: color(gray, lighter);
            color: color(gray, dark);

            &:focus {
                background: color(gray, lightest);
                border-color: color(gray, dark);
                box-shadow: 0 0 0 spacing(smallest) color(gray, light) inset;
            }

            &.active,
            &:hover {
                background: color(gray, darker);
                border-color: color(gray, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }
    }
}

.theme--muted-light {
    .container {
        background: color(gray, base);
        border-color: color(gray, base);
    }

    &.outline {
        .container {
            background: color(gray, white);
            border-color: color(gray, lighter);
            color: color(gray, base);
        }
    }
}

.theme--keyword {
    .container {
        background: color(aqua, dark);
        border-color: color(aqua, dark);
    }

    .prefix-button,
    .suffix-button {
        background: color(aqua, dark);
        border-color: color(aqua, dark);

        &:focus {
            background: color(aqua, darker);
            border-color: color(aqua, darker);
            box-shadow: 0 0 0 spacing(smallest) color(aqua, light) inset;
        }

        &.active,
        &:hover {
            background: color(aqua, darker);
            border-color: color(aqua, darker);
            box-shadow: none;
        }
    }

    .prefix-button {
        border-right-color: color(aqua, base);
    }

    .suffix-button {
        border-left-color: color(aqua, base);
    }

    &.light {
        .container {
            background: color(aqua, lightest);
            border-color: color(aqua, lightest);
            color: color(aqua, darker);
        }

        .prefix-button,
        .suffix-button {
            background: color(aqua, lightest);
            border-color: color(aqua, lightest);
            color: color(aqua, darker);

            &:focus {
                background: color(aqua, lighter);
                border-color: color(aqua, dark);
                box-shadow: 0 0 0 spacing(smallest) color(aqua, light) inset;
            }

            &.active,
            &:hover {
                background: color(aqua, darker);
                border-color: color(aqua, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }

        .prefix-button {
            border-right-color: color(aqua, light);
        }

        .suffix-button {
            border-left-color: color(aqua, light);
        }
    }

    &.outline {
        .container {
            background: color(gray, white);
            border-color: color(aqua, light);
            color: color(aqua, darker);

            &--has-prefix-button {
                border-left: none;
            }

            &--has-suffix-button {
                border-right: none;
            }
        }

        .prefix-button,
        .suffix-button {
            background: color(gray, white);
            border-color: color(aqua, light);
            color: color(aqua, darker);

            &:focus {
                background: color(aqua, lightest);
                border-color: color(aqua, dark);
                box-shadow: 0 0 0 spacing(smallest) color(aqua, base) inset;
            }

            &.active,
            &:hover {
                background: color(aqua, darker);
                border-color: color(aqua, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }
    }
}

.theme--default-darker:not(.light) {
    .container {
        background: color(purple, darker);
        border-color: color(purple, darkest);
    }

    .prefix-button,
    .suffix-button {
        background: color(purple, darker);
        border-color: color(purple, darkest);

        &:focus {
            background: color(purple, base);
            border-color: color(aqua, darker);
            box-shadow: 0 0 0 spacing(smallest) color(purple, base) inset;
        }

        &.active,
        &:hover {
            background: color(purple, dark);
            border-color: color(purple, darkest);
            box-shadow: none;
        }
    }

    .prefix-button {
        border-right-color: color(purple, darkest);
    }

    .suffix-button {
        border-left-color: color(purple, darkest);
    }

    &.outline {
        .container {
            background: color(gray, white);
            border-color: color(purple, darker);
            color: color(purple, darker);

            &--has-prefix-button {
                border-left: none;
            }

            &--has-suffix-button {
                border-right: none;
            }
        }

        .prefix-button,
        .suffix-button {
            background: color(gray, white);
            border-color: color(purple, darker);
            color: color(purple, darker);

            &:focus {
                background: color(purple, lightest);
                border-color: color(purple, darker);
                box-shadow: 0 0 0 spacing(smallest) color(purple, light) inset;
            }

            &.active,
            &:hover {
                background: color(purple, darker);
                border-color: color(purple, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }
    }
}

.theme--blue {
    .container {
        background: color(blue, base);
        border-color: color(blue, base);
    }
}

.theme--blue-light {
    .container {
        background: color(blue, light);
        border-color: color(blue, light);
    }

    .prefix-button,
    .suffix-button {
        background: color(blue, light);
        border-color: color(blue, light);
        color: color(gray, white);

        &:focus {
            background: color(blue, base);
            border-color: color(blue, base);
            box-shadow: 0 0 0 spacing(smallest) color(blue, light) inset;
        }

        &.active,
        &:hover {
            background: color(blue, darker);
            border-color: color(blue, darker);
            box-shadow: none;
        }
    }

    .prefix-button {
        border-right-color: color(blue, lighter);
    }

    .suffix-button {
        border-left-color: color(blue, lighter);
    }

    &.outline {
        .container {
            background: color(gray, white);
            border-color: color(blue, lighter);
            color: color(blue, base);
        }

        .prefix-button,
        .suffix-button {
            background: color(gray, white);
            border-color: color(blue, lighter);
            color: color(blue, base);

            &--has-prefix-button {
                border-left: none;
            }

            &--has-suffix-button {
                border-right: none;
            }

            &:focus {
                background: color(blue, lightest);
                border-color: color(blue, light);
                box-shadow: 0 0 0 spacing(smallest) color(blue, light) inset;
            }

            &.active,
            &:hover {
                background: color(blue, darker);
                border-color: color(blue, darker);
                box-shadow: none;
                color: color(gray, white);
            }
        }
    }
}
