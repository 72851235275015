@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.image {
    display: flex;
    width: breakpoint(sm);
    height: 100%;
    width: 100%;
    background: color(gray, darker) url(images/error-mobile.svg) no-repeat bottom center/cover;

    &--401 {
        background-image: url(images/error-401-mobile.svg);
        background-color: inherit;
    }
    &--403 {
        background-image: url(images/error-403-mobile.svg);
        background-color: inherit;
    }
    &--404 {
        background-image: url(images/error-404-mobile.svg);
        background-color: inherit;
    }
    &--429 {
        background-image: url(images/error-429-mobile.svg);
        background-color: inherit;
    }
    &--account {
        background-image: url(images/error-account-mobile.svg);
        background-color: inherit;
    }
}

@include media-breakpoint-up(sm) {
    .image {
        display: flex;
        width: breakpoint(xl);
        height: 100%;
        width: 100%;
        background: color(gray, darker) url(images/error.svg) no-repeat bottom center/cover;

        &--401 {
            background-image: url(images/error-401.svg);
            background-color: inherit;
        }
        &--403 {
            background-image: url(images/error-403.svg);
            background-color: inherit;
        }
        &--404 {
            background-image: url(images/error-404.svg);
            background-color: inherit;
        }
        &--429 {
            background-image: url(images/error-429.svg);
            background-color: inherit;
        }
        &--account {
            background-image: url(images/error-account.svg);
            background-color: inherit;
        }
    }
}

.content {
    padding: spacing(large) 0;
    width: 100%;
}
