@import 'src/scss/tokens';

.wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.container {
    position: relative;
    svg {
        display: block;
    }
}

.grid {
    color: color(gray, lightest);
}
.axis {
    color: color(gray, light);
}

.axis text {
    color: color(gray, dark);
    font-size: font-size(small);
    font-family: font-family(base);
}

.tooltipContainer {
    position: fixed;
    display: block;
    margin: spacing(small);
}

.indicator {
    stroke: color(gray, darkest);
    stroke-width: 1;
}

.coloredLine {
    width: spacing(base);
    margin-right: spacing(small);
    display: flex;
    align-self: center;
}

.chart-svg {
    overflow: visible;
}
