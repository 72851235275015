@import 'src/scss/mixins';
@import 'src/scss/tokens';

.box {
    transition: background, border-color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}

.position--relative {
    position: relative;
}

@each $colorKey in map-keys($color) {
    @each $tone in map-keys(map-get($color, $colorKey)) {
        .background--#{$colorKey}-#{$tone} {
            background: color($colorKey, $tone);
        }
    }
}

@each $key in map-keys($shadow) {
    .shadow--#{$key} {
        box-shadow: shadow($key);
    }
}

@each $thickness in map-keys($border-width) {
    @each $colorKey in map-keys($color) {
        @each $tone in map-keys(map-get($color, $colorKey)) {
            $solid: border-width($thickness) solid color($colorKey, $tone);
            $dashed: border-width($thickness) dashed color($colorKey, $tone);

            .border--#{$thickness}-solid-#{$colorKey}-#{$tone} {
                border: $solid;
            }

            .border-top--#{$thickness}-solid-#{$colorKey}-#{$tone} {
                border-top: $solid !important;
            }

            .border-bottom--#{$thickness}-solid-#{$colorKey}-#{$tone} {
                border-bottom: $solid !important;
            }

            .border-left--#{$thickness}-solid-#{$colorKey}-#{$tone} {
                border-left: $solid !important;
            }

            .border-right--#{$thickness}-solid-#{$colorKey}-#{$tone} {
                border-right: $solid !important;
            }

            .border--#{$thickness}-dashed-#{$colorKey}-#{$tone} {
                border: $dashed !important;
            }

            .border-top--#{$thickness}-dashed-#{$colorKey}-#{$tone} {
                border-top: $dashed !important;
            }

            .border-bottom--#{$thickness}-dashed-#{$colorKey}-#{$tone} {
                border-bottom: $dashed !important;
            }

            .border-left--#{$thickness}-dashed-#{$colorKey}-#{$tone} {
                border-left: $dashed !important;
            }

            .border-right--#{$thickness}-dashed-#{$colorKey}-#{$tone} {
                border-right: $dashed !important;
            }
        }
    }
}

.border-top--none {
    border-top: none;
}

.border-bottom--none {
    border-bottom: none;
}

.border-left--none {
    border-left: none;
}

.border-right--none {
    border-right: none;
}

@each $key in map-keys($border-radius) {
    .border-radius--#{$key} {
        border-radius: border-radius($key);
    }

    .border-radius-top-left--#{$key} {
        border-top-left-radius: border-radius($key);
    }

    .border-radius-top-right--#{$key} {
        border-top-right-radius: border-radius($key);
    }

    .border-radius-bottom-right--#{$key} {
        border-bottom-right-radius: border-radius($key);
    }

    .border-radius-bottom-left--#{$key} {
        border-bottom-left-radius: border-radius($key);
    }
}

@each $size in map-keys($breakpoint) {
    $prefix: breakpoint-prefix($size);

    @include media-breakpoint-up($size) {
        // PADDING

        @each $size in map-keys($spacing) {
            .#{$prefix}padding--#{$size} {
                padding: spacing($size);
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}padding-horizontal--#{$size} {
                padding-left: spacing($size);
                padding-right: spacing($size);
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}padding-vertical--#{$size} {
                padding-bottom: spacing($size);
                padding-top: spacing($size);
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}padding-top--#{$size} {
                padding-top: spacing($size);
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}padding-bottom--#{$size} {
                padding-bottom: spacing($size);
            }
        }

        // We generate .padding-bottom--remainder-X-Y classes for so
        // that we can have content inside the box equally spaced top and bottom.
        //
        // For example, if we have a padding-top of `larger` on a box, content in the
        // box that has margin-bottom of `base` then we would use the
        // .padding-bottom--remainder-larger-base class so that we visually have
        // the same spacing on top and bottom.
        @each $base in map-keys($spacing) {
            @each $size in map-keys($spacing) {
                @if $base != $size and spacing($base) > spacing($size) {
                    .#{$prefix}padding-bottom--remainder-#{$base}-#{$size} {
                        padding-bottom: spacing-remainder($base, $size);
                    }
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}padding-left--#{$size} {
                padding-left: spacing($size);
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}padding-right--#{$size} {
                padding-right: spacing($size);
            }
        }

        // MARGIN

        @each $size in map-keys($spacing) {
            .#{$prefix}margin--#{$size} {
                margin: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin---#{$size} {
                    margin: (0- spacing($size));
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}margin-horizontal--#{$size} {
                margin-left: spacing($size);
                margin-right: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin-horizontal---#{$size} {
                    margin-left: (0 - spacing($size));
                    margin-right: (0 - spacing($size));
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}margin-vertical--#{$size} {
                margin-bottom: spacing($size);
                margin-top: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin-vertical---#{$size} {
                    margin-bottom: (0 - spacing($size));
                    margin-top: (0 - spacing($size));
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}margin-top--#{$size} {
                margin-top: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin-top---#{$size} {
                    margin-top: (0 - spacing($size));
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}margin-bottom--#{$size} {
                margin-bottom: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin-bottom---#{$size} {
                    margin-bottom: (0 - spacing($size));
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}margin-left--#{$size} {
                margin-left: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin-left---#{$size} {
                    margin-left: (0 - spacing($size));
                }
            }
        }

        @each $size in map-keys($spacing) {
            .#{$prefix}margin-right--#{$size} {
                margin-right: spacing($size);
            }

            @if $size != 'none' {
                .#{$prefix}margin-right---#{$size} {
                    margin-right: (0 - spacing($size));
                }
            }
        }

        .#{$prefix}margin--auto {
            margin: auto;
        }

        .#{$prefix}margin-horizontal--auto {
            margin-right: auto;
            margin-left: auto;
        }

        .#{$prefix}margin-vertical--auto {
            margin-top: auto;
            margin-bottom: auto;
        }

        .#{$prefix}margin-right--auto {
            margin-right: auto;
        }

        .#{$prefix}margin-bottom--auto {
            margin-bottom: auto;
        }

        .#{$prefix}margin-left--auto {
            margin-left: auto;
        }

        .#{$prefix}margin-top--auto {
            margin-top: auto;
        }

        // DISPLAY

        .#{$prefix}display--none {
            display: none;
        }

        .#{$prefix}display--block {
            display: block;
        }

        .#{$prefix}display--inline-block {
            display: inline-block;
        }

        .#{$prefix}display--flex {
            display: flex;
        }

        .#{$prefix}display--grid {
            display: grid;
        }
    }
}
