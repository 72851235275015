@import 'src/scss/mixins';
@import 'src/scss/tokens';

.dropdown-content {
    display: flex;
    flex-direction: column;
    height: 400px;
    margin-top: -#{spacing(small)};
    margin-bottom: -#{spacing(small)};
}

.keyword-message {
    padding: spacing(small) spacing(base);
    border-radius: border-radius(round);
    background-color: color(gray, lightest);
    transition: box-shadow transition-duration(base) $transition-timing-function;

    &:hover {
        box-shadow: inset 0 0 0 1px color(gray, dark);
    }
}

.topic-group-list-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex-grow: 1;
    background-color: color(gray, white);

    &:after {
        content: '';
        flex-grow: 1;
        background: linear-gradient(color(gray, white), rgba(color(gray, white), 0) 50%),
                    url(images/dropdown-bg.svg) center bottom;
    }
}

.topic-group-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.topic-group-name {
    position: sticky;
    top: 0;
    margin-right: -#{spacing(base)};
    margin-left: -#{spacing(base)};
    padding: spacing(small) spacing(base);
    background-color: color(gray, white);
}

.topic-group-logo {
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: color(purple, base);
}

.topic-name {
    height: input-height(base);
    padding: spacing(small) spacing(base);
    border: border-width(base) solid color(gray, lightest);
    border-radius: border-radius(round);
    background-color: color(gray, lightest);
    transition: border-color, background-color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &.active {
        border: border-width(base) solid color(gray, light);
        background-color: color(gray, white);
    }

    .label {
        @include ellipsis();
    }
}

.tooltip-image {
    width: 60px;
    height: 60px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.footer {
    padding: spacing(smaller) spacing(base);
    border-top: border-width(base) solid color(gray, lightest);
}
