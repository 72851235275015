@import 'src/scss/tokens';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.stats-container {
    background-color: color(gray, white);
    border-radius: border-radius(rounder);
    padding: spacing(base);
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.stats-content {
    margin-left: spacing(base);
    width: 100%;
    overflow: hidden;
}

.hint-panel {
    display: inline-grid;
    width: 100%;
    height: 100%;
}
