@import 'src/scss/tokens';

.bubble-chart-container {
    height: 340px;
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
}

.content-container {
    min-width: 250px;
    background: color(gray, white);
    box-shadow: shadow(deep);
    border-radius: border-radius(rounder);
}