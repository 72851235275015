@import 'src/scss/tokens';

.status-card-wrapper {
    height: 100%;
    padding: spacing(small) 0;
}

.status-card {
    height: 100%;
    padding: 0 spacing(base);
    border: border-width(base) solid color(gray, light);
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
}

.status-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.logo {
    height: 24px;
}

.status-card-tooltip-content {
    width: 190px;
    margin: 8px 0;
}
