@import 'src/scss/mixins';
@import 'src/scss/tokens';

.items {
    display: flex;
    flex-direction: column;
}

.related-keywords {
    display: flex;
    flex-direction: column;
}

.dropdown {
    padding-top: spacing(small);
    padding-bottom: spacing(small);
}

.link {
    vertical-align: baseline;
    font-size: font-size(small);
    color: color(purple, dark);
    cursor: pointer;
}

.auto-opt-icon {
    color: color(blue, light);
    width: input-height(base);
    height: input-height(base);
    display: flex;
    justify-content: center;
    align-items: center;
}

@include media-breakpoint-up(sm) {
    .items {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .related-keywords {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
