@import 'src/scss/tokens';
@import 'src/scss/mixins';

$nav-width: 275px;

@include media-breakpoint-up(lg) {
    .container {
        margin-left: $nav-width + spacing(large);
    }
}
