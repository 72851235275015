@import 'src/scss/tokens';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    font-size: font-size(largest);
    color: color(gray, light);
}
