@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    position: relative;
    z-index: 0;
    display: inline-flex;
    height: input-height(base);
    padding: spacing(smallest);
    border: border-width(base) solid color(gray, light);
    border-radius: border-radius(round);
    background-color: color(gray, white);

    &.regular {
        .handler-left {
            & + .label:after {
                background-color: color(gray, lightest);
            }
        }

        .handler-right {
            & + .label:after {
                background-color: color(purple, darker);
            }

            &:checked + .label {
                color: color(gray, white);
            }
        }
    }

    &.equal {
        .handler-left,
        .handler-right {
            & + .label:after {
                background-color: color(purple, darker);
            }

            &:checked + .label {
                color: color(gray, white);
            }
        }
    }
}

.handler {
    display: none;

    &.handler-left + .label {
        &:after {
            left: 100%;
        }
    }

    &.handler-right {
        & + .label {
            margin-left: spacing(smallest);

            &:after {
                left: -100%;
            }
        }
    }

    &:checked + .label {
        pointer-events: none;

        &:after {
            left: 0;
            width: 100%;
        }
    }
}

.label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: spacing(smaller) spacing(base);
    border: border-width(base) solid transparent;
    border-radius: border-radius(base);
    font-weight: font-weight(bold);
    color: color(gray, dark);
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;

    &:hover {
        border-color: color(gray, white);
        color: color(gray, white);
        background-color: color(purple, dark);
    }

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        width: 0;
        height: 100%;
        border-radius: border-radius(base);
        transition: background-color transition-duration(base) $transition-timing-function,
                    left transition-duration(base) $transition-timing-function,
    }
}
