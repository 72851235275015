@import 'src/scss/mixins';
@import 'src/scss/tokens';

.logo {
    height: 35px;
}

.auto-opt-icon-container {
    height: input-height(base);
    padding: spacing(smaller);
    border: border-width(base) solid color(gray, light);
    border-radius: border-radius(round);

    .auto-opt-icon-background {
        background-color: color(blue, light);
        height: 100%;
        padding: spacing(smaller);
        border-radius: border-radius(base);
        color: color(gray, white);
    }

    svg {
        height: 100%;
        width: 100%;
    }
}
