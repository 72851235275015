@import 'src/scss/tokens';

.container {
    padding-right: spacing(small) !important;
    padding-left: spacing(small) !important;
    width: min-content;
    font-size: font-size(base) !important;

    &.read-only {
        pointer-events: none;
    }
}

.default {
    border-color: color(gray, lightest);
    color: color(gray, dark);
    background-color: color(gray, lightest);

    & .topic-logo svg {
        color: color(gray, dark);
        fill: color(gray, dark);
        stroke: color(gray, dark);
    }

    &:active,
    &.active,
    &:hover {
        border-color: color(gray, darker);
        color: color(gray, white);
        background-color: color(gray, darker);

        & .topic-logo svg {
            color: color(gray, white);
            fill: color(gray, white);
            stroke: color(gray, white);
        }
    }
}

.muted {
    border-color: color(gray, base);
    color: color(gray, white);
    background-color: color(gray, base);
    pointer-events: none;

    & .topic-logo svg {
        color: color(gray, white);
        fill: color(gray, white);
        stroke: color(gray, white);
    }
}

.targeted {
    border-color: color(aqua, darker);
    color: color(gray, white);
    background-color: color(aqua, darker);

    & .topic-logo svg {
        color: color(gray, white);
        fill: color(gray, white);
        stroke: color(gray, white);
    }

    &:active,
    &.active,
    &:hover {
        border-color: color(aqua, darkest);
        background-color: color(aqua, darkest);
    }
}

.blue {
    border-color: color(blue, darker);
    color: color(gray, white);
    background-color: color(blue, darker);

    & .topic-logo svg {
        color: color(gray, white);
        fill: color(gray, white);
        stroke: color(gray, white);
    }

    &:active,
    &.active,
    &:hover {
        border-color: color(blue, darkest);
        background-color: color(blue, darkest);
    }
}

.blue-outline {
    border-color: color(blue, darker);
    color: color(blue, darker);
    background-color: color(gray, white);

    & .topic-logo svg {
        color: color(blue, darker);
        fill: color(blue, darker);
        stroke: color(blue, darker);
    }

    &:active,
    &.active,
    &:hover {
        border-color: color(blue, darkest);
        background-color: color(blue, darkest);
        color: color(gray, white);
        fill: color(gray, white);
        stroke: color(gray, white);

        & .topic-logo svg {
            color: color(gray, white);
            fill: color(gray, white);
            stroke: color(gray, white);
        }
    }
}

.blocked {
    border-color: color(red, darker);
    color: color(red, darker);
    background-color: color(gray, white);

    & .topic-logo svg {
        color: color(red, darker);
        fill: color(red, darker);
        stroke: color(red, darker);
    }

    &:active,
    &.active,
    &:hover {
        color: color(gray, white);
        background-color: color(red, darker);

        & .topic-logo svg {
            color: color(gray, white);
            fill: color(gray, white);
            stroke: color(gray, white);
        }
    }
}

.topic-logo {
    display: flex;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    justify-content: center;
    align-items: center;
}
