@import 'src/scss/tokens';
@import 'src/scss/mixins';

.header {
    box-shadow: shadow(base);
}

.content-container {
    min-width: 250px;
    background: color(gray, white);
    box-shadow: shadow(deep);
    border-radius: border-radius(rounder);
}