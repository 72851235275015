@import 'src/scss/tokens';
@import 'src/scss/mixins';

.channel {
    display: flex;
    align-items: center;
    padding-bottom: spacing(base);

    .thumbnail {
        margin-right: spacing(small);
        border-radius: 50%;
        height: 60px;
        width: 60px;
    }

    .title {
        font-size: font-size(large);
        font-weight: font-weight(bold);
        padding-top: spacing(smaller);
        margin-top: (0 - spacing(smaller));

        a {
            color: color(gray, darkest);
            border-bottom: border-width(base) solid transparent;
            transition: border-color;
            transition-duration: transition-duration(base);
            transition-timing-function: $transition-timing-function;

            &:hover {
                border-color: color(gray, darkest);
            }
        }

        .info {
            display: inline-block;
            padding-left: spacing(small);
        }
    }

    .subscribers {
        font-size: font-size(small);
        color: color(gray, dark);
        margin-top: spacing(smallest);
    }
}

.metric {
    display: flex;
    margin-bottom: spacing(smaller);
    justify-content: space-between;

    .name {
        color: color(gray, dark);
    }

    .value {
        text-align: right;
        font-weight: font-weight(bold);
    }
}

.topics-title {
    font-weight: font-weight(bold);
    padding: spacing(small) 0;
}
