@import 'src/scss/tokens';

.header {
    display: flex;
    align-items: center;
    padding: spacing(base) + spacing(small) spacing(base);
}

.logo {
    margin-right: auto;
}

.logo-image {
    height: 22px;
}

.chevron {
    display: flex;
    height: font-size(largest);
    margin-left: spacing(base);
    font-size: font-size(largest);
    font-weight: font-weight(bolder);
    transition: color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
    color: color(gray, darker);
}

.counter-container {
    height: font-size(largest);
}
