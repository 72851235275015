@import 'src/scss/tokens';

.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: spacing(smallest) spacing(small);
    border-radius: 999px;
    font-weight: font-weight(bold);
    color: color(gray, white);
    background-color: color(purple, darker);
}
