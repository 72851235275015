@import 'src/scss/tokens';
@import 'src/scss/mixins';

.line-through {
    display: flex;
    background-color: color(gray, lightest);
    height: 1px;
    border: 0;
}

.full-height {
    height: 100%;
    padding: 0 0 spacing(small) 0;
}

.strategy-option {
    height: 100%;
    padding: spacing(base);
    border: border-width(base) solid color(gray, light);
    border-radius: border-radius(rounder);
    color: color(gray, dark);
    transition: transition-duration(base);

    &:hover {
        border: border-width(base) solid color(purple, dark);
        color: color(purple, dark);

        .meter-square-filled {
            border: border-width(base) solid color(purple, dark);
            background-color: color(purple, dark);
        }
    }
}

.strategy-option--selected {
    @extend .strategy-option;
    border: border-width(base) solid color(purple, darker);
    color: color(purple, darker);

    &:hover {
        border: border-width(base) solid color(purple, darker);
        color: color(purple, darker);
    }
}

.strategy-option--read-only {
    @extend .strategy-option;
    &:hover {
        border: border-width(base) solid color(gray, light);
        color: color(gray, dark);

        .meter-square-filled {
            border: border-width(base) solid color(gray, base);
            background-color: color(gray, base);
        }
    }
}

.meter-square {
    height: 14px;
    width: 14px;
    border-radius: border-radius(small);
    border: border-width(base) solid color(gray, lighter);
    display: inline-block;
    margin-left: spacing(smallest);
    transition: transition-duration(base);

    &-filled {
        @extend .meter-square;
        border: border-width(base) solid color(gray, base);
        background-color: color(gray, base);
    }

    &-filled--selected {
        @extend .meter-square;
        background-color: color(purple, darker);
        border: border-width(base) solid color(purple, darker);
    }
}
