@import 'src/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    margin-top: -#{spacing(smaller)};
    margin-bottom: -#{spacing(smaller)};
}

.option {
    position: relative;
    margin-top: spacing(smaller);
    margin-bottom: spacing(smaller);

    &.read-only {
        pointer-events: none;


        .label {
            border-color: color(gray, lightest);
            background-color: color(gray, lightest);
        }
    }
}

.radio {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    border-width: 0;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    overflow: hidden;
    padding: 0;

    &:focus + .label {
        border-color: color(purple, darker);
        box-shadow: 0 0 0 spacing(smaller) rgba(color(purple, lighter), 0.75);
    }

    &:checked + .label {
        border-color: color(purple, darker);
        color: color(purple, darker);
        &:hover {
            border-color: color(purple, darker);
            color: color(purple, darker);
            cursor: initial;
        }
    }

    & + .label {
        &:hover {
            border-color: color(purple, dark);
            color: color(purple, dark);
        }
    }
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    height: input-height(base);
    padding: 0 spacing(base);
    border: border-width(base) solid color(gray, light);
    border-radius: border-radius(round);
    color: color(gray, dark);
    background-color: color(gray, white);
    user-select: none;
    cursor: pointer;
    transition: border-color, color, background-color, box-shadow;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
    padding: spacing(large) spacing(base);
}

.icon-container {
    margin-right: spacing(small);
    margin-top: 5px;
    font-size: font-size(larger);
}

.label-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: font-weight(bold);
}
