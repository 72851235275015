@import 'src/scss/mixins';
@import 'src/scss/tokens';

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 spacing(base);
}

.size--small {
    max-width: container-width(small);
}

.size--medium {
    max-width: container-width(medium);
}

.size--base {
    max-width: container-width(base);
}

.size--full-width {
    max-width: 100%;
}

@include media-breakpoint-up(sm) {
    .container {
        padding: 0 spacing(large);
    }
}
