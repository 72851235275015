@import 'src/scss/mixins';
@import 'src/scss/tokens';

.panel-container {
    background-color: color(gray, background);
    margin-top: -(spacing(small));
    margin-bottom: 0;
    padding-top: spacing(large);
    padding-bottom: spacing(large);
}

.insight-box-content-container {
    display: flex;
    justify-content: space-between;
}

.content-items-left {
    flex: 1; 
    overflow: hidden;
}

.primary-text {
    font-weight: font-weight(bold);
    font-size: font-size(base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-items-right {
    width: auto;
    text-align: right;
   
}

.icon-and-stats {
    display: inline-block;
    margin-left: spacing(base);
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
}

.icon-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 40px;
}

.icon-container > svg {
    display: block;
    margin: 0 auto;
    text-align: center;
    color: color(purple, light)
  }
