@import 'src/scss/tokens';

.value > span {
    vertical-align: middle;
}

.bar {
    height: 4px;
    border-radius: 2px;
    background: color(gray, lighter);
    overflow: hidden;
}

.bar-value {
    height: 100%;
    border-radius: 2px;

    @each $colorKey in map-keys($color) {
        @each $tone in map-keys(map-get($color, $colorKey)) {
            &.color--#{$colorKey}-#{$tone} {
                background: color($colorKey, $tone);
            }
        }
    }
}
