@import "./tokens";

* {
    box-sizing: border-box;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    border: 0;
    font-size: 100%;
    font-weight: font-weight(base);
    font: inherit;
    line-height: line-height(base);
    margin: 0;
    padding: 0;
    vertical-align: top;
}

html {
    -ms-overflow-style: scrollbar;
}

body, textarea {
    background: color(gray, white);
    color: color(gray, darkest);
    font-family: font-family(base);
    font-size: font-size(base);
    line-height: line-height(base);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}

a,
a:visited {
    color: color(purple, dark);
    text-decoration: none;
}

a:active,
a:hover {
    color: color(purple, darker);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

*[hidden] {
    display: none;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
