@import 'src/scss/mixins';
@import 'src/scss/tokens';

.menu {
    max-width: 800px;
}

.container {
    &.active,
    &:hover {
        .icon-wrapper {
            color: color(gray, white);
        }

        .hover-hidden {
            display: none;
        }

        .hover-show {
            display: block;
        }

        .counter {
            background-color: color(gray, white);
        }
    }

    &.active {
        .counter {
            color: color(purple, darker);
        }
    }

    &:hover {
        .counter {
            color: color(purple, dark);
        }
    }
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: spacing(small) - border-width(base);
    margin-right: spacing(small);
    font-size: font-size(larger);
    color: color(purple, dark);
    transition: color transition-duration(base) $transition-timing-function;
}

.icon {
    &.hover-show {
        display: none;
    }
}

.counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    margin-right: spacing(smaller) - border-width(base);
    border-radius: border-radius(base);
    font-weight: font-weight(bolder);
    color: color(gray, white);
    background-color: color(red, dark);
    transition-property: color, background-color;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}
