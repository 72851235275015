@import 'src/scss/tokens';

.wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: visible;
}

.container {
    flex: 1 0;
    overflow: visible;
    position: relative;
    height: 100%;

    svg {
        display: block;
        width: 100%;
    }
}

.legend {
    flex: 0 0;
}
