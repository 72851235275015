@import 'src/scss/mixins';

.sb__clearfix {
    @include clearfix;
}

.page {
    min-height: 100vh;
}

.full-height {
    height: 100%;

    > * {
        height: 100%;
    }
}
