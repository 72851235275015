@import 'src/scss/tokens';

// fade-scale

.fade-scale-enter,
.fade-scale-appear {
    opacity: 0;
    transform: scale(1.075, 1.075);
}

.fade-scale-enter-active,
.fade-scale-appear-active {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity, transform;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}

.fade-scale-exit {
    opacity: 1;
    transform: scale(1, 1);
}

.fade-scale-exit-done {
    opacity: 0;
    transform: scale(1.075, 1.075);
}

.fade-scale-exit-active {
    opacity: 0;
    transform: scale(1.075, 1.075);
    transition: opacity, transform;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}

// fade

.fade-enter,
.fade-appear {
    opacity: 0;
}

.fade-enter-active,
.fade-appear-active {
    opacity: 1;
    transition: opacity;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-done {
    opacity: 0;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity;
    transition-duration: transition-duration(base);
    transition-timing-function: $transition-timing-function;
}

// fade-slide-left
$slide-distance: 50%;

.fade-slide-left-enter,
.fade-slide-left-appear {
    transform: translateX(33%);
}

.fade-slide-left-enter-active,
.fade-slide-left-appear-active {
    transform: translateX(0);
    transition: transform;
    transition-duration: transition-duration(slow);
    transition-timing-function: $transition-timing-function;
}

.fade-slide-left-exit {
    transform: translateX(0);
}

.fade-slide-left-exit-done {
    transform: translateX(33%);
}

.fade-slide-left-exit-active {
    transform: translateX(33%);
    transition: transform;
    transition-duration: transition-duration(slow);
    transition-timing-function: $transition-timing-function;
}

.slower {
    transition-duration: transition-duration(slower);
}
