@import 'src/scss/mixins';
@import 'src/scss/tokens';

.help {
    .section {
        border-left: border-width(thick) solid color(gray, lightest);
        margin-left: spacing(base);
        padding-left: spacing(base);
    }

    .title-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: spacing(small);
        width: 100%;

        .bar {
            border-top: border-width(thick) solid color(gray, lightest);
            flex-grow: 1;
            height: 0;
        }
        .chevron {
            font-size: font-size(largest);
            color: color(gray, light);
        }
    }

    h1 {
        font-size: font-size(larger);
        font-weight: font-weight(bold);
    }

    h2 {
        font-size: font-size(large);
        font-weight: font-weight(bold);
        padding: spacing(base) 0;
    }

    h3 {
        font-size: font-size(large);
        font-weight: font-weight(bold);
    }

    p {
        padding: spacing(small) 0;
    }
}
