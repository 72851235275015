@import 'src/scss/tokens';

.category {
    min-width: 135px;
    font-weight: font-weight(bold);
    font-size: font-size(large);
    color: color(gray, darkest);

    .time {
        margin-top: spacing(smallest);
        font-weight: font-weight(bold);
        font-size: font-size(small);
        color: color(gray, base);
    }

    .icon {
        float: right;
        color: color(red, dark);
    }

    &.seen {
        font-weight: font-weight(base);

        .icon {
            color: color(gray, light);
        }
    }
}

.content {
    min-height: 40px;
    margin: 0 spacing(base) 0 spacing(small);
    padding-left: spacing(base);
    border-left: border-width(thick) solid color(red, dark);
    color: color(gray, darkest);
    font-weight: font-weight(bold);
    font-size: font-size(large);

    &.seen {
        border-left: border-width(base) solid color(gray, lighter);
        font-weight: font-weight(base);
    }
}
