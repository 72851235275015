@import 'src/scss/mixins';
@import 'src/scss/tokens';

$godzilla-size: 45px; // Too big to be present in tokens.scss

.container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: color(purple, darker);
}

.promo {
    .logo {
        display: none;
    }

    .headline {
        font-weight: font-weight(bolder);
        font-size: font-size(largest);
        color: color(gray, white);
    }
}

.login-form {
    margin: 0 auto;
    padding: spacing(base) spacing(large);
    border-radius: border-radius(rounder);
    background-color: color(gray, white);
    box-shadow: shadow(deep);
}

.logo {
    height: 50px;
}

@include media-breakpoint-up(sm) {
    .promo {
        .headline {
            font-size: font-size(huge);
        }
    }

    .login-form {
        width: 480px;
    }
}

@include media-breakpoint-up(md) {
    .promo {
        .logo {
            display: block;
        }
    }

    .login-form {
        width: 320px;
    }
}

@include media-breakpoint-up(lg) {
    .promo {
        .headline {
            font-size: $godzilla-size;
        }
    }
}
